import pkg from '../../package.json';
import React from 'react';
import { Link } from 'react-router-dom';
import classNames from 'classnames';
import { connectToStore } from '../store';
import { routes } from '../config';
import Button from '../shared/button/Button';
import logoSrc from '../images/logo.png';
import styles from './NavigationSidebar.module.scss';

function logOut(actions) {
	actions.session.reset();
}

export function NavigationLink({ route, activePath }) {
	const cls = classNames({
		[styles.navLink]: true,
		[styles.navLinkActive]: route.path === activePath,
	});

	return (
		<Link to={route.path} className={cls}>
			{route.title}
		</Link>
	);
}

function NavigationSidebar({ state, actions, activePath }) {
	const { email } = state.session;

	return (
		<div className={styles.navBar}>
			<div className={styles.logoSection}>
				<img src={logoSrc} className={styles.logoImage} alt="Logo" />
			</div>
			<nav className={styles.navSection}>
				{routes.map((route, index) => <NavigationLink key={index} route={route} activePath={activePath} />)}
			</nav>
			<div className={styles.userSection}>
				<div className={styles.userEmail}>{email}</div>
				<Button onClick={() => logOut(actions)}>Log Out</Button>
			</div>
			<div className={styles.versionSection}>
				<span>v{pkg.version}</span>
			</div>
		</div>
	);
}

export default connectToStore(NavigationSidebar);
