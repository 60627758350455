import React from 'react';
import classNames from 'classnames';
import styles from './FormElement.module.scss';

function FormElement({ className, handleSubmit, children }) {
	const cls = classNames({
		[styles.formElement]: true,
		[className]: !!className,
	});

	return (
		<form onSubmit={handleSubmit} className={cls}>
			{children}
		</form>
	);
}

export default FormElement;
