import React from 'react';
import classNames from 'classnames';
import styles from './TabItem.module.scss';

function TabItem({ className, selected, children }) {
	const cls = classNames({
		[styles.tabItem]: true,
		[styles.tabButtonSelected]: selected,
		[className]: !!className,
	});

	return (
		<div className={cls}>
			{children}
		</div>
	);
}

export default TabItem;
