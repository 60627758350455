/**
 * @file Action creators.
 */

import { default as actionTypes } from './actionTypes';
import * as config from '../../config';
import { getRequestHeaders, getNewTokens } from '../apiHelpers';
import { receiveAccessToken } from '../session/actionCreators';

function startLoadItemRequest() {
	return {
		type: actionTypes.START_LOAD_ITEM_REQUEST,
		payload: {
			operation: `load`,
			errorMessage: undefined,
			loading: true,
			success: false,
		},
	};
}

function startSaveItemRequest() {
	return {
		type: actionTypes.START_SAVE_ITEM_REQUEST,
		payload: {
			operation: `save`,
			errorMessage: undefined,
			loading: true,
			success: false,
		},
	};
}

function receiveLoadItemSuccessResponse(itemData) {
	return {
		type: actionTypes.RECEIVE_SUCCESS_RESPONSE,
		payload: {
			operation: undefined,
			itemData,
			errorMessage: undefined,
			loading: false,
			success: true,
		},
	};
}

function receiveSaveItemSuccessResponse(originalItemData, changes) {
	return {
		type: actionTypes.RECEIVE_SUCCESS_RESPONSE,
		payload: {
			operation: undefined,
			itemData: { ...originalItemData, ...changes },
			errorMessage: undefined,
			loading: false,
			success: true,
			lastSaved: Date.now(),
		},
	};
}

export function receiveErrorResponse(errorMessage) {
	return {
		type: actionTypes.RECEIVE_ERROR_RESPONSE,
		payload: {
			errorMessage,
			loading: false,
			success: false,
		},
	};
}

async function makeLoadItemRequest(accessToken, itemId) {
	const opts = {
		method: `get`,
		headers: getRequestHeaders(accessToken),
	};
	return fetch(`${config.variables.BACKEND_API_URL}/media/content_tags/${itemId}`, opts);
}

export function loadItem(itemId) {
	return async (dispatch, getState) => {
		try {
			if (itemId === `new`) {
				dispatch(receiveLoadItemSuccessResponse({}));
				return;
			}

			dispatch(startLoadItemRequest());

			const state = getState();
			const { accessToken, refreshToken, email } = state.session;

			let res;
			res = await makeLoadItemRequest(accessToken, itemId);

			if (res.status === 401) {
				const newTokens = await getNewTokens(accessToken, refreshToken);
				const newTokensObj = await newTokens.json();
				dispatch(receiveAccessToken(email, newTokensObj.response.token, newTokensObj.response.refresh_token));
				res = await makeLoadItemRequest(newTokensObj.response.token, itemId);
			}

			if (res.status !== 200) throw new Error(`${res.status} ${res.statusText}`);

			const body = await res.json();

			dispatch(receiveLoadItemSuccessResponse(body.response));
		} catch (err) {
			dispatch(receiveErrorResponse(err.message));
		}
	};
}

async function makeSaveItemRequest(accessToken, itemData) {
	const opts = {
		method: `post`,
		headers: getRequestHeaders(accessToken),
		body: JSON.stringify(itemData),
	};
	const itemId = itemData.content_tag_id || ``;
	return fetch(`${config.variables.BACKEND_API_URL}/media/content_tags/${itemId}`, opts);
}

export function saveItem(formValues) {
	return async (dispatch, getState) => {
		try {
			dispatch(startSaveItemRequest());

			const state = getState();
			const { accessToken, refreshToken, email } = state.session;
			let res;

			const itemData = { ...state.contentTagEditor.itemData, ...formValues };

			res = await makeSaveItemRequest(accessToken, itemData);

			if (res.status === 401) {
				const newTokens = await getNewTokens(accessToken, refreshToken);
				const newTokensObj = await newTokens.json();
				dispatch(receiveAccessToken(email, newTokensObj.response.token, newTokensObj.response.refresh_token));
				res = await makeSaveItemRequest(newTokensObj.response.token, itemData);
			}

			if (res.status !== 200) throw new Error(`${res.status} ${res.statusText}`);

			const body = await res.json();

			dispatch(receiveSaveItemSuccessResponse(itemData, body.response));
		} catch (err) {
			dispatch(receiveErrorResponse(err.message));
		}
	};
}

export function resetItemData() {
	return { type: actionTypes.RESET_ITEM_DATA };
}

export function reset() {
	return { type: actionTypes.RESET };
}
