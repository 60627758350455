import React from 'react';
import { Switch, Route } from 'react-router-dom';
import { connectToStore } from '../store';
import NavigationSidebar from './NavigationSidebar';
import { routes } from '../config';
import Login from '../screens/login/Login';
import NotFound from '../screens/not-found/NotFound';
import styles from './App.module.scss';

export function UnauthenticatedView() {
  return (
    <div className={styles.screenContainer}>
      <Login />
    </div>
  );
}

export function AuthenticatedView() {
  return (
    <Switch>
      {/* Routes */}
      {routes.map((route, index) => (
        <Route key={index} path={route.path} exact={route.exact}>
          <NavigationSidebar activePath={route.path} />
          <div className={styles.screenContainer}>
            <route.component />
          </div>
        </Route>
      ))}

      {/* 404 */}
      <Route path="*">
        <NavigationSidebar />
        <div className={styles.screenContainer}>
          <NotFound />
        </div>
      </Route>
    </Switch >
  );
}

function App({ state }) {
  const { authenticated } = state.session;

  return (
    <div className={styles.app}>
      {authenticated ? <AuthenticatedView /> : <UnauthenticatedView />}
    </div>
  );
}

export default connectToStore(App);
