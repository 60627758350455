export function renderCellUrl(data) {
	const value = data.valueFormatted || data.value;
	return `<a href="${value}" target="_blank">${value}</a>`;
}

export function renderCellEmail(data) {
	const value = data.valueFormatted || data.value;
	return `<a href="mailto:${value}" target="_blank">${value}</a>`;
}

export function renderCellYesNo(data) {
	const value = String(data.valueFormatted || data.value);

	switch (value) {
		case `true`:
			return `Yes`;

		case `false`:
			return `No`;

		default:
			return `-`;
	}
}
