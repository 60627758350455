import Statistics from '../screens/statistics/Statistics';
import Clients from '../screens/clients/Clients';
import Experts from '../screens/experts/Experts';
import Users from '../screens/users/Users';
import Sessions from '../screens/sessions/Sessions';
import Subscriptions from '../screens/subscriptions/Subscriptions';
import Content from '../screens/content/Content';
import Reports from '../screens/reports/Reports';
import MyAccount from '../screens/my-account/MyAccount';

export const routes = [
	{
		path: `/`,
		exact: true,
		title: `Statistics`,
		component: Statistics,
	},
	{
		path: `/clients`,
		exact: false,
		title: `Clients`,
		component: Clients,
	},
	{
		path: `/experts`,
		exact: false,
		title: `Experts`,
		component: Experts,
	},
	{
		path: `/users`,
		exact: false,
		title: `Users`,
		component: Users,
	},
	{
		path: `/sessions`,
		exact: false,
		title: `Sessions`,
		component: Sessions,
	},
	{
		path: `/subscriptions`,
		exact: false,
		title: `Subscriptions`,
		component: Subscriptions,
	},
	{
		path: `/content`,
		exact: false,
		title: `Content`,
		component: Content,
	},
	{
		path: `/reports`,
		exact: false,
		title: `Reports`,
		component: Reports,
	},
	{
		path: `/my-account`,
		exact: true,
		title: `My Account`,
		component: MyAccount,
	},
];
