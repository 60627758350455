import React from 'react';
import moment from 'moment';
import FormLabelCell from '../form-label-cell/FormLabelCell';
import FormFieldCell from '../form-field-cell/FormFieldCell';
import FormErrorCell from '../form-error-cell/FormErrorCell';

function InfoFirstCreated({ created }) {
	return (
		<tr>
			<FormLabelCell label="First Created" />
			<FormFieldCell>{created ? moment(created).format(`DD/MM/YYYY @ HH:mm`) : `-`}</FormFieldCell>
			<FormErrorCell />
		</tr>
	);
}

export default InfoFirstCreated;
