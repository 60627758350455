import React from 'react';
import classNames from 'classnames';
import styles from './FormLabelCell.module.scss';

function FormLabelCell({ className, label }) {
	const cls = classNames({
		[styles.formLabelCell]: true,
		[className]: !!className,
	});

	return (
		<td className={cls}>
			<label>{label}:</label>
		</td>
	);
}

export default FormLabelCell;
