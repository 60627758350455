import React, { useEffect, useRef } from 'react';
import classNames from 'classnames';
import { variables } from '../../config/index';
import Loader from '../loader/Loader';
import styles from './FilePreview.module.scss';

function arrayBufferToBase64(buf) {
    const bytes = [].slice.call(new Uint8Array(buf));
    let binary = '';

    bytes.forEach(b => (binary += String.fromCharCode(b)));
    return window.btoa(binary);
}

async function obtainMediaFile(accessToken, filekey, imageEl, setPreviewState, setPreviewError) {
    try {
        if (filekey.split('/').pop() === '') throw new Error(`No preview available.\nThe selected file is a directory.`);
        const opts = {
            method: `get`,
            headers: {
                authorization: `Bearer ${accessToken}`,
            },
        };

        setPreviewState(`LOADING`);
        setPreviewError(``);
        const res = await fetch(`${variables.BACKEND_API_URL}/media?key=${filekey}`, opts);
        if (res.status === 400) throw new Error(`Invalid key`);
        else if (res.status === 404) throw new Error(`File not found`);
        else if (res.status < 200 || res.status > 299) throw new Error(`Status code ${res.status} received`);

        const buf = await res.arrayBuffer();
        const contentType = res.headers.get(`content-type`);
        const imageStr = arrayBufferToBase64(buf);
        imageEl.current.src = `data:${contentType};base64,${imageStr}`;

        setPreviewState(`COMPLETE`);
    } catch (err) {
        setPreviewState(`ERROR`);
        setPreviewError(err.message);
    }
}

function PreviewImage({ accessToken, inputValue, previewState, setPreviewState, previewError, setPreviewError }) {
    const imageEl = useRef();
    const isClickable = Boolean(inputValue);
    const clsPreviewImage = classNames({
        [styles.previewImage]: true,
        [styles.previewImageClickable]: isClickable,
    });
    const clsImgTag = classNames({
        [styles.image]: true,
        [styles.imageVisible]: previewState === `COMPLETE`,
    });

    useEffect(() => {
        if (inputValue !== '')
            obtainMediaFile(accessToken, inputValue, imageEl, setPreviewState, setPreviewError)
    }, [inputValue, accessToken, setPreviewState, setPreviewError]);

    return (
        <div
            onClick={() => isClickable && obtainMediaFile(accessToken, inputValue, imageEl, setPreviewState, setPreviewError)}
            className={clsPreviewImage}
        >
            {[`NONE`, `ERROR`].includes(previewState) && isClickable && (
                <div className={styles.imageInstructions}>
                    {previewState === `NONE` && <span className={styles.imageInstructionsPlain}>{`Click to load preview`}</span>}
                    {previewState === `ERROR` && (
                        <span className={styles.imageInstructionsError}>{previewError || `Unknown error`}</span>
                    )}
                </div>
            )}
            {previewState === `LOADING` && <Loader mode="dark" className={styles.imageLoader} />}
            <img ref={imageEl} alt="Preview" className={clsImgTag} />
        </div>
    );
}

function Preview({ fileType, ...args }) {
    switch (fileType) {
        case `image`:
            return PreviewImage({ ...args });
        default:
            return <></>;
    }
}

export default Preview;
