import React, { useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import classNames from 'classnames';
import { connectToStore } from '../../store';
import Highlights from '../../shared/highlights/Highlights';
import Button from '../../shared/button/Button';
import Toolbar from '../../layout/Toolbar';
import { TableIcon, RefreshIcon } from '../../shared/icons';
import styles from './Statistics.module.scss';
import AppointmentsChart from './past_sessions/AppointmentsChart';
import PieChart from './conditions_treated/PieChart';
import Section from '../../layout/Section';
import { navigateToUser, navigateToExpert, DataView } from './upcoming-sessions/sessions-table';
import { constructHighlights } from "./highlights/constructHighlights";

export function Statistics({ state, actions, className }) {
	const { tableState, loading, success, errorMessage, statsData } = state.statistics;
	const highlights = constructHighlights(state);
	const history = useHistory();
	const [tableCtrl, setTableCtrl] = useState(undefined);
	const navigateToUserBound = navigateToUser.bind(undefined, history);
	const navigateToExpertBound = navigateToExpert.bind(undefined, history);
	const cls = classNames({
		[styles.screen]: true,
		[className]: !!className,
	});
	useEffect(() => actions.statistics.loadData(), [actions.statistics]);

	return (
		<div className={cls}>
			<Highlights highlights={highlights} />

			<Section
				title={`Upcoming Confirmed Sessions`}
				className={styles.sectionContainer}
				bodyClassName={styles.tableSectionBody}
			>
				<Toolbar>
					<Button onClick={() => actions.statistics.loadData()} disabled={loading}>
						<RefreshIcon />
						<span>Refresh</span>
					</Button>
					<Button onClick={() => tableCtrl && tableCtrl.reset()} disabled={loading || !success}>
						<TableIcon />
						<span>Reset Table</span>
					</Button>
				</Toolbar>
				<DataView
					loading={loading}
					success={success}
					errorMessage={errorMessage}
					actions={actions}
					rowData={statsData.upcoming_sessions || []}
					tableState={tableState}
					setTableCtrl={setTableCtrl}
					navigateToUserBound={navigateToUserBound}
					navigateToExpertBound={navigateToExpertBound}
				/>
			</Section>

			{statsData.past_sessions_data && statsData.past_sessions_data[0] &&
				<Section
					title={`Number of Sessions`}
					className={styles.sectionContainer}
				>
					<div className={styles['stat-charts']}>
						<div className={styles['stat-group']}>
							<div className={styles['stat-group__cell']}>
								<AppointmentsChart data={statsData.past_sessions_data} interval={'Days'} />
							</div>
						</div>
					</div>
				</Section>
			}
			{statsData.conditions_treated_data && statsData.conditions_treated_data.values &&
				<Section
					title={`Conditions Treated`}
					className={styles.sectionContainer}
					bodyClassName={styles.piechartSectionBody}
				>
					<PieChart
						width={600}
						title={`Conditions treated`}
						data={statsData.conditions_treated_data.values}
						labels={statsData.conditions_treated_data.labels}
						colors={['#016077', '#FFCD41', '#07BAB4 ', '#F4992A']}
						hoverColors={['#004353', '#b25a5f', '#04827d', '#aa9a4a']} />
				</Section>
			}
		</div>
	);
}

export default connectToStore(Statistics);
