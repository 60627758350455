import React from 'react';
import classNames from 'classnames';
import styles from './FormFieldCell.module.scss';

function FormFieldCell({ className, children }) {
	const cls = classNames({
		[styles.formFieldCell]: true,
		[className]: !!className,
	});

	return (
		<td className={cls}>
			{children}
		</td>
	);
}

export default FormFieldCell;
