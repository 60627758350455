/**
 * @file Action creators.
 */

import { default as actionTypes } from './actionTypes';
import * as config from '../../config';
import { getNewTokens, getRequestHeaders } from '../apiHelpers';
import { receiveAccessToken } from '../session/actionCreators';

export function addReport(category, report) {
	return {
		type: actionTypes.ADD_REPORT,
		payload: { category, report },
	};
}

export function addReportCategory(category) {
	return {
		type: actionTypes.ADD_REPORT_CATEGORY,
		payload: { category },
	};
}

export function updateReportsState(reportsState) {
	return {
		type: actionTypes.UPDATE_REPORTS_STATE,
		payload: {
			reportsState,
		},
	};
}

function startGetReportsRequest() {
	return {
		type: actionTypes.START_LOAD_DATA_REQUEST,
		payload: {
			errorMessage: undefined,
			loading: true,
			success: false,
		},
	};
}

function receiveSuccessResponse(reportData) {
	return {
		type: actionTypes.RECEIVE_SUCCESS_RESPONSE,
		payload: {
			reportsState: reportData,
			errorMessage: undefined,
			loading: false,
			success: true,
		},
	};
}

export function receiveErrorResponse(errorMessage) {
	return {
		type: actionTypes.RECEIVE_ERROR_RESPONSE,
		payload: {
			errorMessage,
			loading: false,
			success: false,
		},
	};
}

async function makeGetRequest(accessToken, interval) {
	const opts = {
		method: `get`,
		headers: getRequestHeaders(accessToken),
	};
	return fetch(
		`${config.variables.BACKEND_API_URL}/statistics/reports${
			interval ? `?month=${interval.month}&year=${interval.year}` : ``
		}`,
		opts,
	);
}

export function loadData(interval) {
	return async (dispatch, getState) => {
		try {
			dispatch(startGetReportsRequest());

			const state = getState();
			const { accessToken, refreshToken, email } = state.session;
			let res;

			res = await makeGetRequest(accessToken, interval);

			if (res.status === 401) {
				const newTokens = await getNewTokens(accessToken, refreshToken);
				const newTokensObj = await newTokens.json();
				dispatch(receiveAccessToken(email, newTokensObj.response.token, newTokensObj.response.refresh_token));
				res = await makeGetRequest(newTokensObj.response.token);
			}

			if (res.status !== 200) throw new Error(`${res.status} ${res.statusText}`);

			const body = await res.json();

			dispatch(receiveSuccessResponse(body.response));
		} catch (err) {
			dispatch(receiveErrorResponse(err.message));
		}
	};
}

export function reset() {
	return { type: actionTypes.RESET };
}
