import React from 'react';
import moment from 'moment';
import FormLabelCell from '../form-label-cell/FormLabelCell';
import FormFieldCell from '../form-field-cell/FormFieldCell';
import FormErrorCell from '../form-error-cell/FormErrorCell';

function InfoLastUpdated({ updated }) {
	return (
		<tr>
			<FormLabelCell label="Last Updated" />
			<FormFieldCell>{updated ? moment(updated).format(`DD/MM/YYYY @ HH:mm`) : `-`}</FormFieldCell>
			<FormErrorCell />
		</tr>
	);
}

export default InfoLastUpdated;
