import React, { useRef } from 'react';
import classNames from 'classnames';
import { connectToStore } from '../../store';
import Button from '../../shared/button/Button';
import styles from './Login.module.scss';

function handleEmailFieldReturnKey(evt, passwordEl) {
	if (evt.keyCode !== 13) return;
	passwordEl.current.focus();
}

function handlePasswordFieldReturnKey(evt, executeLogIn) {
	if (evt.keyCode !== 13) return;
	executeLogIn();
}

function attemptLogIn(actions, emailEl, passwordEl) {
	const email = emailEl.current.value;
	const password = passwordEl.current.value;
	actions.session.attemptLogIn(email, password);
}

function Login({ state, actions }) {
	const { loading, errorMessage } = state.session;
	const emailEl = useRef(``);
	const passwordEl = useRef(``);
	const executeLogIn = () => attemptLogIn(actions, emailEl, passwordEl);
	const errorBoxCls = classNames({
		[styles.errorBox]: true,
		[styles.errorBoxVisible]: errorMessage,
	});

	return (
		<div className={styles.screen}>
			<form className={styles.form}>
				<div className={styles.title}>Login</div>
				<table className={styles.table}>
					<tbody>
						<tr>
							<td>Email:</td>
							<td><input ref={emailEl} type="email" disabled={loading} onKeyUp={(evt) => handleEmailFieldReturnKey(evt, passwordEl)} /></td>
						</tr>
						<tr>
							<td>Password:</td>
							<td><input ref={passwordEl} type="password" disabled={loading} onKeyUp={(evt) => handlePasswordFieldReturnKey(evt, executeLogIn)} /></td>
						</tr>
					</tbody>
				</table>
				<div className={errorBoxCls}>Error: {errorMessage}</div>
				<Button onClick={executeLogIn} loading={loading} className={styles.button}>
					Login
				</Button>
			</form>
		</div>
	);
}

export default connectToStore(Login);
