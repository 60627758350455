import React from 'react';
import { Prompt } from 'react-router-dom';

function FormUnsavedChangesPrompt({ pristine, redirectToNewItemId }) {
	return (
		<Prompt
			when={!pristine && !redirectToNewItemId}
			message={() => `There are unsaved changes. Are you sure you want to leave this page?`}
		/>
	);
}

export default FormUnsavedChangesPrompt;
