import * as config from '../config';

/**
 * Constructs and returns a dictionary of headers to add to outgoing requests.
 * @param {string|undefined} accessToken The user access token to add to the auth header, optional.
 * @returns The dictionary of request headers.
 */
export function getRequestHeaders(accessToken) {
	const headers = {
		'accept': `application/json`,
		'content-type': `application/json`,
	};
	if (accessToken) headers[`authorization`] = `Bearer ${accessToken}`;

	return headers;
}
export async function getNewTokens(accessToken, refreshToken) {
	const body = { refresh_token: refreshToken, device_id: `INTERNAL_DASHBOARD` };

	const opts = {
		method: `post`,
		headers: getRequestHeaders(accessToken),
		body: JSON.stringify(body),
	};

	const res = await fetch(`${config.variables.BACKEND_API_URL}/authentication/refreshToken`, opts);

	if (res.status !== 200) throw new Error(`${res.status} ${res.statusText}`);
	return res;
}
