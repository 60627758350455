import React from 'react';
import { Switch, Route, useRouteMatch } from 'react-router-dom';
import { connectToStore } from '../../store';
import ClientList from './client-list/ClientList';
import ClientDetails from './client-details/ClientDetails';
import NotFound from '../not-found/NotFound';
import styles from './Clients.module.scss';

const subRoutes = [
	{
		path: `/`,
		exact: true,
		component: ClientList,
	},
	{
		path: `/details/:id`,
		exact: true,
		component: ClientDetails,
	},
];

function Clients() {
	const { path } = useRouteMatch();

	return (
		<div className={styles.screen}>
			<Switch>
				{/* Routes */}
				{subRoutes.map((route, index) => (
					<Route key={index} path={`${path}${route.path}`} exact={route.exact}>
						<route.component />
					</Route>
				))}

				{/* 404 */}
				<Route path="*">
					<NotFound />
				</Route>
			</Switch>
		</div>
	);
}

export default connectToStore(Clients);
