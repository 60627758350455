/**
 * @file Action types.
 */

import { createActionTypes } from '../utilities';

const namespacedActionTypes = createActionTypes(`content-theme-editor`, [
	`START_LOAD_ITEM_REQUEST`,
	`START_SAVE_ITEM_REQUEST`,
	`RECEIVE_SUCCESS_RESPONSE`,
	`RECEIVE_ERROR_RESPONSE`,
	`RESET_ITEM_DATA`,
]);

const globalActionTypes = createActionTypes(undefined, [
	`RESET`,
]);

export default {
	...namespacedActionTypes,
	...globalActionTypes,
};
