import React from 'react';
import classNames from 'classnames';
import styles from './FormErrorCell.module.scss';

function FormErrorCell({ className, meta, isNew }) {
	const cls = classNames({
		[styles.formErrorCell]: true,
		[className]: !!className,
	});
	const valueHasChanged = !meta?.pristine || isNew;

	return <td className={cls}>{meta?.error && meta?.touched && valueHasChanged && <span>{meta.error}</span>}</td>;
}

export default FormErrorCell;
