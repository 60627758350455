import React from 'react';
import { useRouteMatch } from 'react-router-dom';
import classNames from 'classnames';
import { connectToStore } from '../../../store';
import { useQuery } from '../../../lib/utilities';
import Highlights from '../../../shared/highlights/Highlights';
import TabContainer from '../../../layout/TabContainer';
import Tab from '../../../layout/Tab';
import ItemsTab from './ItemsTab';
import ThemesTab from './ThemesTab';
import TagsTab from './TagsTab';
import styles from './ContentList.module.scss';

function constructHighlights(state) {
	return [{
		colour: `#07BAB4`,
		value: state.contentItems.rowData.length,
		label: `Content items`,
	}, {
		colour: `#07BAB4`,
		value: state.contentItems.rowData.filter(row => row.status === `DRAFT`).length,
		label: `Drafts`,
	}, {
		colour: `#07BAB4`,
		value: state.contentThemes.rowData.length,
		label: `Themes`,
	}, {
		colour: `#07BAB4`,
		value: state.contentTags.rowData.length,
		label: `Tags`,
	}];
}

function ContentList({ state, className }) {
	const { url } = useRouteMatch();
	const query = useQuery();
	const highlights = constructHighlights(state);
	const cls = classNames({
		[styles.contentList]: true,
		[className]: !!className,
	});
	const clsTabContainer = classNames({
		[styles.tabContainer]: true,
	});

	return (
		<div className={cls}>
			<Highlights highlights={highlights} />
			<TabContainer className={clsTabContainer} url={url} selectedTabId={query.tabId} defaultTabId={`items`}>
				<Tab tabId="items" title="Content"><ItemsTab /></Tab>
				<Tab tabId="themes" title="Themes"><ThemesTab /></Tab>
				<Tab tabId="tags" title="Tags"><TagsTab /></Tab>
			</TabContainer>
		</div>
	);
}

export default connectToStore(ContentList);
