/**
 * @file Reducer.
 */

import { default as actionTypes } from './actionTypes';
import { shallowUpdateState } from '../utilities';

export const EMPTY_ITEM_DATA = Object.freeze({});

export const INITIAL_STATE = Object.freeze({
	operation: undefined,
	itemData: EMPTY_ITEM_DATA,
	errorMessage: undefined,
	loading: true,
	success: false,
	lastSaved: 0,
});

export default function reducer(state = INITIAL_STATE, action) {
	const { type, payload } = action;

	switch (type) {
		case actionTypes.START_LOAD_ITEM_REQUEST:
			return shallowUpdateState(state, payload);

		case actionTypes.START_SAVE_ITEM_REQUEST:
			return shallowUpdateState(state, payload);

		case actionTypes.RECEIVE_SUCCESS_RESPONSE:
			return shallowUpdateState(state, payload);

		case actionTypes.RECEIVE_ERROR_RESPONSE:
			return shallowUpdateState(state, payload);

		case actionTypes.RESET_ITEM_DATA:
			return shallowUpdateState(state, { itemData: { ...EMPTY_ITEM_DATA } });

		case actionTypes.RESET:
			return shallowUpdateState(state, INITIAL_STATE);

		default:
			return state;
	}
}
