import React, { useState, useEffect } from 'react';
import { useRouteMatch, useHistory } from 'react-router-dom';
import classNames from 'classnames';
import { connectToStore } from '../../../store';
import Highlights from '../../../shared/highlights/Highlights';
import Button from '../../../shared/button/Button';
import Toolbar from '../../../layout/Toolbar';
import { PlusIcon, TableIcon, RefreshIcon } from '../../../shared/icons';
import Table from '../../../shared/table/Table';
import { AgGridColumn } from 'ag-grid-react';
import styles from './ExpertList.module.scss';

const ID_FIELD_KEY = `therapist_id`;

function constructHighlights(state) {
	return [
		{
			colour: `#07BAB4`,
			value: state.expertList.rowData.length,
			label: `MHE`,
		},
		{
			colour: `#07BAB4`,
			value: state.expertList.rowData.filter(row => row.active).length,
			label: `Active MHE`,
		},
	];
}

function navigateToItem(history, url, evt) {
	history.push(`${url}/details/${evt.data[ID_FIELD_KEY]}`);
}

function DataView({ loading, success, errorMessage, actions, rowData, tableState, setTableCtrl, navigateToItemBound }) {
	if (!loading && !success) {
		return (
			<div className={styles.errorView}>
				<span>Error: {errorMessage || `An unknown error ocurred`}</span>
			</div>
		);
	}

	return (
		<Table
			loading={loading}
			rowData={rowData}
			tableState={tableState}
			onStateChange={tableState => actions.expertList.updateTableState(tableState)}
			onTableReady={tableCtrl => setTableCtrl(tableCtrl)}
		>
			<AgGridColumn type="id" field={ID_FIELD_KEY} onCellDoubleClicked={navigateToItemBound}></AgGridColumn>
			<AgGridColumn
				type="navigation"
				headerName="First Name"
				field="first_name"
				width={125}
				onCellDoubleClicked={navigateToItemBound}
			></AgGridColumn>
			<AgGridColumn
				type="navigation"
				headerName="Last Name"
				field="last_name"
				width={125}
				onCellDoubleClicked={navigateToItemBound}
			></AgGridColumn>
			<AgGridColumn type="email" headerName="Email" field="email" minWidth={275} flex={1}></AgGridColumn>
			<AgGridColumn headerName="Company" field="company" width={200}></AgGridColumn>
			<AgGridColumn headerName="Role" field="role" width={100}></AgGridColumn>
			<AgGridColumn type="yesno" headerName="Enabled" field="enabled" width={125}></AgGridColumn>
			<AgGridColumn type="yesno" headerName="Active" field="active" width={100}></AgGridColumn>
			<AgGridColumn type="datetime" headerName="Created" field="created" sort="desc"></AgGridColumn>
		</Table>
	);
}

function ExpertList({ state, actions, className }) {
	const { tableState, loading, success, errorMessage, rowData } = state.expertList;
	const highlights = constructHighlights(state);
	const history = useHistory();
	const [tableCtrl, setTableCtrl] = useState(undefined);
	const { url } = useRouteMatch();
	const navigateToItemBound = navigateToItem.bind(undefined, history, url);
	const cls = classNames({
		[styles.expertList]: true,
		[className]: !!className,
	});
	useEffect(() => actions.expertList.loadData(), [actions.expertList]);

	return (
		<div className={cls}>
			<Highlights highlights={highlights} />
			<Toolbar>
				<Button to={`${url}/details/new`}>
					<PlusIcon />
					<span>Add MHE</span>
				</Button>
				<Button onClick={() => actions.expertList.loadData()} disabled={loading}>
					<RefreshIcon />
					<span>Refresh</span>
				</Button>
				<Button onClick={() => tableCtrl && tableCtrl.reset()} disabled={loading || !success}>
					<TableIcon />
					<span>Reset Table</span>
				</Button>
			</Toolbar>
			<DataView
				loading={loading}
				success={success}
				errorMessage={errorMessage}
				actions={actions}
				rowData={rowData}
				tableState={tableState}
				setTableCtrl={setTableCtrl}
				navigateToItemBound={navigateToItemBound}
			/>
		</div>
	);
}

export default connectToStore(ExpertList);
