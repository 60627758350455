import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlus, faTable, faSync, faLongArrowAltLeft, faSave, faTimes } from '@fortawesome/free-solid-svg-icons';

export function PlusIcon(props) {
	return (
		<FontAwesomeIcon icon={faPlus} {...props} />
	);
}

export function TableIcon(props) {
	return (
		<FontAwesomeIcon icon={faTable} {...props} />
	);
}

export function RefreshIcon(props) {
	return (
		<FontAwesomeIcon icon={faSync} {...props} />
	);
}

export function LeftArrowIcon(props) {
	return (
		<FontAwesomeIcon icon={faLongArrowAltLeft} {...props} />
	);
}

export function SaveIcon(props) {
	return (
		<FontAwesomeIcon icon={faSave} {...props} />
	);
}

export function CloseIcon(props) {
	return (
		<FontAwesomeIcon icon={faTimes} {...props} />
	);
}

