import React, { useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { Form, Field } from 'react-final-form';
import classNames from 'classnames';
import { connectToStore } from '../../../store';
import Section from '../../../layout/Section';
import Toolbar from '../../../layout/Toolbar';
import FlexSpace from '../../../layout/FlexSpace';
import Button from '../../../shared/button/Button';
import { LeftArrowIcon, SaveIcon } from '../../../shared/icons';
import FormSuccessfulCreationRedirect from '../../../shared/form-successful-creation-redirect/FormSuccessfulCreationRedirect';
import FormLoadingOverlay from '../../../shared/form-loading-overlay/FormLoadingOverlay';
import FormUnsavedChangesPrompt from '../../../shared/form-unsaved-changes-prompt/FormUnsavedChangesPrompt';
import FormElement from '../../../shared/form-element/FormElement';
import FormTable from '../../../shared/form-table/FormTable';
import FormLabelCell from '../../../shared/form-label-cell/FormLabelCell';
import FormFieldCell from '../../../shared/form-field-cell/FormFieldCell';
import FormErrorCell from '../../../shared/form-error-cell/FormErrorCell';
import InfoLastUpdated from '../../../shared/info-last-updated/InfoLastUpdated';
import InfoFirstCreated from '../../../shared/info-first-created/InfoFirstCreated';
import { getFormTitle } from '../../../lib/utilities';
import styles from './ContentThemeEditor.module.scss';

function onValidate(values) {
	const errors = {};

	if (!values.theme_name) errors.theme_name = `Required`;

	return errors;
}

function ContentThemeEditor({ state, actions, className }) {
	const { itemData, loading } = state.contentThemeEditor;
	const { id } = useParams();
	const cls = classNames({
		[styles.contentThemeEditor]: true,
		[className]: !!className,
	});
	const formTitle = getFormTitle(`Theme`, id);
	const redirectToNewItemId = id === `new` && itemData.content_theme_id;

	useEffect(() => {
		actions.contentThemeEditor.loadItem(id);
		return actions.contentThemeEditor.resetItemData;
	}, [actions.contentThemeEditor, id]);

	return (
		<div className={cls}>
			<FormSuccessfulCreationRedirect redirectToNewItemId={redirectToNewItemId} basePath="/content/themes" />
			<FormLoadingOverlay loading={loading} />
			<Form
				onSubmit={values => actions.contentThemeEditor.saveItem(values)}
				validate={onValidate}
				initialValues={itemData}
				validateOnBlur={true}
				render={({ handleSubmit, form, submitting, pristine, valid, values }) => (
					<>
						<FormUnsavedChangesPrompt pristine={pristine} redirectToNewItemId={redirectToNewItemId} />
						<Toolbar>
							<Button onClick={() => window.history.go(-1)} disabled={submitting}>
								<LeftArrowIcon />
								<span>Back</span>
							</Button>
							<FlexSpace />
							<Button onClick={form.submit} disabled={submitting || pristine || !valid}>
								<SaveIcon />
								<span>Save</span>
							</Button>
						</Toolbar>
						<FormElement handleSubmit={handleSubmit}>
							<Section title={formTitle}>
								<FormTable>
									<Field name="theme_name">
										{({ input, meta }) => (
											<tr>
												<FormLabelCell label="Theme Name" />
												<FormFieldCell>
													<input {...input} type="text" />
												</FormFieldCell>
												<FormErrorCell meta={meta} />
											</tr>
										)}
									</Field>
									<InfoLastUpdated updated={values.updated} />
									<InfoFirstCreated created={values.created} />
								</FormTable>
							</Section>
						</FormElement>
					</>
				)}
			/>
		</div>
	);
}

export default connectToStore(ContentThemeEditor);
