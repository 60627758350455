/**
 * @file Aggregator for statistics store.
 */

import reducer from './reducer';
import * as actionCreators from './actionCreators';
import { default as actionTypes } from './actionTypes';

export default {
	reducer,
	actionCreators,
	actionTypes,
};
