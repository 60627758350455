import React from 'react';
import classNames from 'classnames';
import styles from './Toolbar.module.scss';

function Toolbar({ className, children }) {
	const cls = classNames({
		[styles.toolbar]: true,
		[className]: !!className,
	});

	return (
		<div className={cls}>
			{children}
		</div>
	);
}

export default Toolbar;
