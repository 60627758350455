import React from 'react';
import classNames from 'classnames';
import Select from 'react-select';
import Button from '../button/Button';
import { RefreshIcon } from '../icons';
import FlexSpace from '../../layout/FlexSpace';
import styles from './FieldSelect.module.scss';

function prepareSelectedValue(options, inputValue, isMulti) {
	if (!isMulti) {
		return options.find(opt => opt.value === inputValue);
	} else {
		return options.filter(opt => inputValue.find?.(item => item.value === opt.value));
	}
}

function FieldSelect({
	className,
	selectClassName,
	input,
	options,
	isMulti,
	isSearchable,
	loading = false,
	refresh = undefined,
}) {
	const cls = classNames({
		[styles.fieldSelect]: true,
		[className]: !!className,
	});
	const clsSelect = classNames({
		[styles.fieldSelectInner]: true,
		[selectClassName]: !!selectClassName,
	});
	const selectedValue = prepareSelectedValue(options, input.value, isMulti);

	return (
		<div className={cls}>
			<Select
				{...input}
				className={clsSelect}
				options={options}
				isMulti={isMulti}
				isSearchable={isSearchable}
				isDisabled={loading}
				isLoading={loading}
				value={selectedValue}
			/>
			{refresh && (
				<>
					<Button onClick={() => refresh()} disabled={loading}>
						<RefreshIcon />
					</Button>
					<FlexSpace />
				</>
			)}
		</div>
	);
}

export default FieldSelect;
