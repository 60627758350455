import moment from 'moment';

export function formatValueDateTime(data) {
	const value = data.valueFormatted || data.value;
	return moment(value).format(`DD/MM/YYYY HH:mm`);
}

export function formatValueDate(data) {
	const value = data.valueFormatted || data.value;
	return moment(value).format(`DD/MM/YYYY`);
}

export function formatValueTime(data) {
	const value = data.valueFormatted || data.value;
	return moment(value).format(`HH:mm`);
}

export function formatSubscriptionStatus(data) {
	const value = data.valueFormatted || data.value;
	return [undefined, `NONE`].includes(value) ? `` : value;
}
