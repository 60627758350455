/**
 * @file Reducer.
 */

import { default as actionTypes } from './actionTypes';
import { shallowUpdateState } from '../utilities';

const EXISTING_STATE = JSON.parse(localStorage.getItem(`ins-session-state`) || `{}`);

export const EMPTY_STATE = Object.freeze({
	accessToken: undefined,
	authenticated: undefined,
	dateLoggedIn: undefined,
	email: undefined,
	errorMessage: undefined,
	loading: undefined,
});

export const INITIAL_STATE = Object.freeze({
	...EMPTY_STATE,
	accessToken: EXISTING_STATE.accessToken,
	authenticated: EXISTING_STATE.authenticated,
	dateLoggedIn: EXISTING_STATE.dateLoggedIn,
	email: EXISTING_STATE.email,
	errorMessage: EXISTING_STATE.errorMessage,
	loading: EXISTING_STATE.loading,
});

export default function reducer(state = INITIAL_STATE, action) {
	const { type, payload } = action;

	switch (type) {
		case actionTypes.START_LOG_IN_REQUEST:
			return shallowUpdateState(state, payload);

		case actionTypes.RECEIVE_ACCESS_TOKEN:
			return shallowUpdateState(state, payload);

		case actionTypes.RECEIVE_LOG_IN_ERROR:
			return shallowUpdateState(state, payload);

		case actionTypes.RESET:
			return shallowUpdateState(state, EMPTY_STATE);

		default:
			return state;
	}
}
