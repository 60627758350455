/**
 * @file Action types.
 */

import { createActionTypes } from '../utilities';

const namespacedActionTypes = createActionTypes(`report-editor`, [
	`START_LOAD_REPORT_REQUEST`,
	`START_SAVE_REPORT_REQUEST`,
	`RECEIVE_SUCCESS_RESPONSE`,
	`RECEIVE_ERROR_RESPONSE`,
	`RESET_REPORT_DATA`,
	`UPDATE_REPORT`,
]);

const globalActionTypes = createActionTypes(undefined, [
	`RESET`,
]);

export default {
	...namespacedActionTypes,
	...globalActionTypes,
};
