/**
 * @file Reducer.
 */

import { default as actionTypes } from './actionTypes';
import { shallowUpdateState } from '../utilities';

export const INITIAL_STATE = Object.freeze({
	reportsState: {},
	reports: {
		'default': []
	},
	errorMessage: undefined,
	loading: true,
	success: false,
});

export default function reducer(state = INITIAL_STATE, action) {
	const { type, payload } = action;

	switch (type) {
		case actionTypes.ADD_REPORT:
			return {
				...state,
				reports: {
					...state.reports,
					[payload.category]: state.reports[payload.category] ? [...state.reports[payload.category], payload.report] : [payload.report]
				}
			};
		case actionTypes.ADD_REPORT_CATEGORY:
			return {
				...state,
				reports: {
					...state.reports,
					[payload.category]: []
				}
			}
		case actionTypes.UPDATE_REPORTS_STATE:
			return shallowUpdateState(state, payload);

		case actionTypes.START_LOAD_DATA_REQUEST:
			return shallowUpdateState(state, payload);

		case actionTypes.RECEIVE_SUCCESS_RESPONSE:
			return shallowUpdateState(state, payload);

		case actionTypes.RECEIVE_ERROR_RESPONSE:
			return shallowUpdateState(state, payload);

		case actionTypes.RESET:
			return shallowUpdateState(state, INITIAL_STATE);

		default:
			return state;
	}
}
