import { useLocation } from 'react-router-dom';

export function useQuery() {
	const query = new URLSearchParams(useLocation().search);
	const params = {};

	for (const [key, value] of query.entries()) {
		params[key] = value;
	}

	return params;
}

export function getFormTitle(itemName, idUrlParam) {
	if (idUrlParam === `new`) return `Create ${itemName}`;
	return `Edit ${itemName} (#${idUrlParam})`;
}

export function isValidJson(json) {
	try {
		if (!json) return false;
		JSON.parse(json);
		return true;
	} catch (err) {
		return false;
	}
}
