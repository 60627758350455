/**
 * @file Action types.
 */

import { createActionTypes } from '../utilities';

const namespacedActionTypes = createActionTypes(`session`, [
	`START_LOG_IN_REQUEST`,
	`RECEIVE_ACCESS_TOKEN`,
	`RECEIVE_LOG_IN_ERROR`,
]);

const globalActionTypes = createActionTypes(undefined, [
	`RESET`,
]);

export default {
	...namespacedActionTypes,
	...globalActionTypes,
};
