import React, { useState, useEffect } from 'react';
import classNames from 'classnames';
import Button from '../../shared/button/Button';
import Toolbar from '../../layout/Toolbar';
import { TableIcon, RefreshIcon, LeftArrowIcon } from '../../shared/icons';
import Table from '../../shared/table/Table';
import { AgGridColumn } from 'ag-grid-react';
import { connectToStore } from '../../store';
import styles from './FileBrowser.module.scss';
import Modal from '../../layout/Modal';
import Preview from '../../shared/file-preview/FilePreview';

function DataView({ loading, success, errorMessage, actions, rowData, tableState, setTableCtrl,
    accessToken, selectedFile, previewState, setPreviewState, previewError, setPreviewError, selectFileBound, previewFileBound,
}) {
    if (!loading && !success) {
        return (
            <div className={styles.errorView}>
                <span>Error: {errorMessage || `An unknown error ocurred`}</span>
            </div>
        );
    }

    return (
        <div className={styles["content"]}>
            <Table
                loading={loading}
                rowData={rowData}
                tableState={tableState}
                onStateChange={tableState => actions.fileBrowser.updateTableState(tableState)}
                onTableReady={tableCtrl => setTableCtrl(tableCtrl)}
            >
                <AgGridColumn
                    cellStyle={({ data }) => {
                        if (data.isDirectory) return {
                            color: `#07bab4`,
                            fontWeight: 'bold'
                        };
                    }}
                    onCellDoubleClicked={selectFileBound}
                    onCellClicked={previewFileBound}
                    headerName="Filename"
                    field="name"
                    minWidth={300}
                    flex={1}
                    sort="asc"></AgGridColumn>
                <AgGridColumn flex={1} type="datetime" headerName="Date Uploaded" field="date" ></AgGridColumn>
            </Table>
            <div className={styles.previewContainer}>
                <Preview
                    accessToken={accessToken}
                    fileType={"image"}
                    inputValue={selectedFile}
                    previewState={previewState}
                    setPreviewState={setPreviewState}
                    previewError={previewError}
                    setPreviewError={setPreviewError}
                />
            </div>
        </div>

    );
}

const selectFile = (actions, onSelect, onClose, setSelectedFile, setIsRootDir, evt) => {
    // check if file or directory
    if (evt.data.isDirectory) {
        setSelectedFile(evt.data.path.substring(evt.data.path.indexOf('/') + 1));
        actions.fileBrowser.loadData(evt.data.path.substring(evt.data.path.indexOf('/') + 1));
        setIsRootDir(false);

    } else {
        setSelectedFile(evt.data.path.substring(evt.data.path.indexOf('/') + 1));
        onSelect(evt.data.path.substring(evt.data.path.indexOf('/') + 1));
        onClose();
    }
}

const previewFile = (setSelectedFile, evt) => setSelectedFile(evt.data.path.substring(evt.data.path.indexOf('/') + 1))

const goBack = (selectedFile, setSelectedFile, actions, setIsRootDir) => {
    let parentPath = selectedFile.split('/');
    parentPath.pop();
    parentPath.pop();
    if (parentPath.length <= 2) setIsRootDir(true);
    parentPath = parentPath.join('/').concat('/');
    actions.fileBrowser.loadData(parentPath);
    setSelectedFile(parentPath);
}

function FileBrowser({ state, actions, className, onClose, onSelect }) {
    const { tableState, loading, success, errorMessage, rowData } = state.fileBrowser;
    const [tableCtrl, setTableCtrl] = useState(undefined);
    const [selectedFile, setSelectedFile] = useState(`IMAGE/`);
    const [previewState, setPreviewState] = useState(`NONE`);
    const [previewError, setPreviewError] = useState(``);
    const [isRootDir, setIsRootDir] = useState(true);
    const cls = classNames({
        [styles.fileBrowser]: true,
        [className]: !!className,
    });
    const { accessToken } = state.session;
    const selectFileBound = selectFile.bind(undefined, actions, onSelect, onClose, setSelectedFile, setIsRootDir);
    const previewFileBound = previewFile.bind(undefined, setSelectedFile);
    const goBackBound = goBack.bind(undefined, selectedFile, setSelectedFile, actions, setIsRootDir);
    useEffect(() => actions.fileBrowser.loadData(), [actions.fileBrowser]);

    return (
        <Modal
            title={`File Browser: /${selectedFile}`}
            onClose={onClose}
            onAction={() => onSelect(selectedFile)}
            actionDisabled={selectedFile.split('/').pop() === ``}
        >
            <div className={cls}>
                <Toolbar>
                    <Button onClick={goBackBound}
                        disabled={isRootDir}>
                        <LeftArrowIcon />
                    </Button>
                    <Button onClick={() => actions.fileBrowser.loadData()} disabled={loading}>
                        <RefreshIcon />
                        <span>Refresh</span>
                    </Button>
                    <Button onClick={() => tableCtrl && tableCtrl.reset()} disabled={loading || !success}>
                        <TableIcon />
                        <span>Reset Table</span>
                    </Button>
                </Toolbar>
                <DataView
                    loading={loading}
                    success={success}
                    errorMessage={errorMessage}
                    actions={actions}
                    rowData={rowData}
                    tableState={tableState}
                    setTableCtrl={setTableCtrl}
                    accessToken={accessToken}
                    setSelectedFile={setSelectedFile}
                    selectedFile={selectedFile}
                    previewState={previewState}
                    setPreviewState={setPreviewState}
                    previewError={previewError}
                    setPreviewError={setPreviewError}
                    setIsRootDir={setIsRootDir}
                    selectFileBound={selectFileBound}
                    previewFileBound={previewFileBound}
                />
            </div>
        </Modal>
    )
}

export default connectToStore(FileBrowser);
