import React, { useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { Form, Field } from 'react-final-form';
import classNames from 'classnames';
import SimpleMDE from 'react-simplemde-editor';
import { connectToStore } from '../../../store';
import Section from '../../../layout/Section';
import Toolbar from '../../../layout/Toolbar';
import FlexSpace from '../../../layout/FlexSpace';
import Button from '../../../shared/button/Button';
import { LeftArrowIcon, SaveIcon } from '../../../shared/icons';
import FormSuccessfulCreationRedirect from '../../../shared/form-successful-creation-redirect/FormSuccessfulCreationRedirect';
import FormLoadingOverlay from '../../../shared/form-loading-overlay/FormLoadingOverlay';
import FormUnsavedChangesPrompt from '../../../shared/form-unsaved-changes-prompt/FormUnsavedChangesPrompt';
import FormElement from '../../../shared/form-element/FormElement';
import FormTable from '../../../shared/form-table/FormTable';
import FormLabelCell from '../../../shared/form-label-cell/FormLabelCell';
import FormFieldCell from '../../../shared/form-field-cell/FormFieldCell';
import FormErrorCell from '../../../shared/form-error-cell/FormErrorCell';
import InfoLastUpdated from '../../../shared/info-last-updated/InfoLastUpdated';
import InfoFirstCreated from '../../../shared/info-first-created/InfoFirstCreated';
import FieldSelect from '../../../shared/field-select/FieldSelect';
import FieldFileUpload from '../../../shared/field-file-upload/FieldFileUpload';
import { getFormTitle, isValidJson } from '../../../lib/utilities';
import styles from './ContentItemEditor.module.scss';
import 'easymde/dist/easymde.min.css';

const CONTENT_TYPE_OPTIONS = [
	{ value: `ARTICLE`, label: `📝 Article` },
	{ value: `VIDEO`, label: `📹 Video` },
	{ value: `AUDIO`, label: `🎶 Audio` },
	{ value: `RECIPE`, label: `🥗 Recipe` },
	{ value: `PODCAST`, label: `🎙 Podcast` },
	{ value: `EXERCISE`, label: `⛹️‍♀️ Exercise` },
];
const STATUS_OPTIONS = [
	{ value: `DRAFT`, label: `🟡 Draft` },
	{ value: `PUBLISHED`, label: `🟢 Published` },
];

function mapThemeItemsToOptions(items) {
	return items.map(item => ({
		value: item.content_theme_id,
		label: item.theme_name,
	}));
}

function mapTagItemsToOptions(items) {
	return items.map(item => ({
		value: item.content_tag_id,
		label: item.tag_name,
	}));
}

function onValidate(values) {
	const errors = {};
	const contentType = values.content_type?.value ?? values.content_type;

	if (!values.title) errors.title = `Required`;
	if (!contentType) errors.content_type = `Required`;
	if (!values.status) errors.status = `Required`;
	if (!values.author) errors.author = `Required`;
	if (!values.theme_ids || !values.theme_ids.length) errors.theme_ids = `Required`;
	if (!values.tag_ids || !values.tag_ids.length) errors.tag_ids = `Required`;
	if (!values.summary) errors.summary = `Required`;

	switch (contentType) {
		case `ARTICLE`:
			if (!values.body) errors.body = `Required`;
			break;

		case `VIDEO`:
			if (!values.media_file_key) errors.media_file_key = `Required`;
			if (!values.body) errors.body = `Required`;
			break;

		case `AUDIO`:
			if (!values.media_file_key) errors.media_file_key = `Required`;
			if (!values.body) errors.body = `Required`;
			break;

		case `RECIPE`:
			if (!values.metadata) errors.metadata = `Required`;
			if (!values.body) errors.body = `Required`;
			break;

		case `PODCAST`:
			if (!values.media_file_key) errors.media_file_key = `Required`;
			if (!values.body) errors.body = `Required`;
			break;

		case `EXERCISE`:
			if (!values.exercise_definition) errors.exercise_definition = `Required`;
			if (!values.body) errors.body = `Required`;
			break;

		default:
			break;
	}

	if (!errors.metadata && values.metadata && !isValidJson(values.metadata)) errors.metadata = `Invalid JSON`;
	if (!errors.exercise_definition && values.exercise_definition && !isValidJson(values.exercise_definition)) {
		errors.exercise_definition = `Invalid JSON`;
	}

	return errors;
}

function ContentItemEditor({ state, actions, className }) {
	const { accessToken } = state.session;
	const { itemData, loading } = state.contentItemEditor;
	const { rowData: themeItems, loading: themesLoading } = state.contentThemes;
	const { rowData: tagItems, loading: tagsLoading } = state.contentTags;
	const { id } = useParams();
	const cls = classNames({
		[styles.contentItemEditor]: true,
		[className]: !!className,
	});
	const formTitle = getFormTitle(`Content Item`, id);
	const isNew = id === `new`;
	const redirectToNewItemId = isNew && itemData.content_item_id;
	const themeOptions = mapThemeItemsToOptions(themeItems);
	const tagOptions = mapTagItemsToOptions(tagItems);

	useEffect(() => {
		actions.contentItemEditor.loadItem(id);
		actions.contentThemes.loadData();
		actions.contentTags.loadData();
		return actions.contentItemEditor.resetItemData;
	}, [actions.contentItemEditor, actions.contentThemes, actions.contentTags, id]);

	return (
		<div className={cls}>
			<FormSuccessfulCreationRedirect redirectToNewItemId={redirectToNewItemId} basePath="/content/items" />
			<FormLoadingOverlay loading={loading} />
			<Form
				onSubmit={values => actions.contentItemEditor.saveItem(values)}
				validate={onValidate}
				initialValues={itemData}
				validateOnBlur={true}
				render={({ handleSubmit, form, submitting, pristine, valid, values }) => {
					const contentType = values.content_type?.value || itemData.content_type;
					return (
						<>
							<FormUnsavedChangesPrompt pristine={pristine} redirectToNewItemId={redirectToNewItemId} />
							<Toolbar>
								<Button onClick={() => window.history.go(-1)} disabled={submitting}>
									<LeftArrowIcon />
									<span>Back</span>
								</Button>
								<FlexSpace />
								<Button onClick={form.submit} disabled={submitting || pristine || !valid}>
									<SaveIcon />
									<span>Save</span>
								</Button>
							</Toolbar>
							<FormElement handleSubmit={handleSubmit}>
								<Section title={formTitle}>
									<FormTable>
										<Field name="title">
											{({ input, meta }) => (
												<tr>
													<FormLabelCell label="Title" />
													<FormFieldCell>
														<input {...input} type="text" className={styles.fieldTitle} />
													</FormFieldCell>
													<FormErrorCell meta={meta} isNew={isNew} />
												</tr>
											)}
										</Field>
										<Field name="content_type">
											{({ input, meta }) => (
												<tr>
													<FormLabelCell label="Type" />
													<FormFieldCell>
														<FieldSelect input={input} options={CONTENT_TYPE_OPTIONS} />
													</FormFieldCell>
													<FormErrorCell meta={meta} isNew={isNew} />
												</tr>
											)}
										</Field>
										<Field name="status">
											{({ input, meta }) => (
												<tr>
													<FormLabelCell label="Status" />
													<FormFieldCell>
														<FieldSelect input={input} options={STATUS_OPTIONS} />
													</FormFieldCell>
													<FormErrorCell meta={meta} isNew={isNew} />
												</tr>
											)}
										</Field>
										<Field name="author">
											{({ input, meta }) => (
												<tr>
													<FormLabelCell label="Author" />
													<FormFieldCell>
														<input {...input} type="text" className={styles.fieldAuthor} />
													</FormFieldCell>
													<FormErrorCell meta={meta} isNew={isNew} />
												</tr>
											)}
										</Field>
										<Field name="theme_ids">
											{({ input, meta }) => (
												<tr>
													<FormLabelCell label="Themes" />
													<FormFieldCell>
														<FieldSelect
															input={input}
															selectClassName={styles.fieldThemes}
															options={themeOptions}
															isMulti={true}
															isSearchable={true}
															loading={themesLoading}
															refresh={() => actions.contentThemes.loadData()}
														/>
													</FormFieldCell>
													<FormErrorCell meta={meta} isNew={isNew} />
												</tr>
											)}
										</Field>
										<Field name="tag_ids">
											{({ input, meta }) => (
												<tr>
													<FormLabelCell label="Tags" />
													<FormFieldCell>
														<FieldSelect
															input={input}
															selectClassName={styles.fieldTags}
															options={tagOptions}
															isMulti={true}
															isSearchable={true}
															loading={tagsLoading}
															refresh={() => actions.contentTags.loadData()}
														/>
													</FormFieldCell>
													<FormErrorCell meta={meta} isNew={isNew} />
												</tr>
											)}
										</Field>
										<Field name="summary">
											{({ input, meta }) => (
												<tr>
													<FormLabelCell label="Summary" />
													<FormFieldCell>
														<textarea {...input} className={styles.fieldSummary} />
													</FormFieldCell>
													<FormErrorCell meta={meta} isNew={isNew} />
												</tr>
											)}
										</Field>
										<Field name="metadata">
											{({ input, meta }) => (
												<tr>
													<FormLabelCell label="Metadata" />
													<FormFieldCell>
														<textarea {...input} className={styles.fieldMetadata} />
													</FormFieldCell>
													<FormErrorCell meta={meta} isNew={isNew} />
												</tr>
											)}
										</Field>
										<Field name="cover_photo_key">
											{({ input, meta }) => (
												<tr>
													<FormLabelCell label="Cover Photo" />
													<FormFieldCell>
														<FieldFileUpload accessToken={accessToken} input={input} fileType="image" />
													</FormFieldCell>
													<FormErrorCell meta={meta} isNew={isNew} />
												</tr>
											)}
										</Field>
									</FormTable>
								</Section>
								<Section title={`Details${contentType ? ` for ${contentType}` : ``}`}>
									<FormTable>
										<Field name="exercise_definition">
											{({ input, meta }) => (
												<tr className={[`EXERCISE`].includes(contentType) ? `` : styles.formFieldHidden}>
													<FormLabelCell label="Exercise Definition" />
													<FormFieldCell>
														<textarea {...input} className={styles.fieldMetadata} />
													</FormFieldCell>
													<FormErrorCell meta={meta} isNew={isNew} />
												</tr>
											)}
										</Field>
										<Field name="media_file_key" className="wtf">
											{({ input, meta }) => (
												<tr
													className={[`VIDEO`, `AUDIO`, `PODCAST`].includes(contentType) ? `` : styles.formFieldHidden}
												>
													<FormLabelCell label="Media File" />
													<FormFieldCell>
														<input {...input} type="text" />
													</FormFieldCell>
													<FormErrorCell meta={meta} isNew={isNew} />
												</tr>
											)}
										</Field>
										<Field name="body">
											{({ input, meta }) => (
												<tr className={contentType ? `` : styles.formFieldHidden}>
													<FormLabelCell label="Body" />
													<FormFieldCell>
														<SimpleMDE
															{...input}
															options={{
																placeholder: `Type something here...`,
																promptURLs: true,
																sideBySideFullscreen: false,
																blockStyles: {
																	italic: '_',
																},
																toolbar: [
																	'heading-1',
																	'heading-2',
																	'heading-3',
																	'|',
																	'bold',
																	'italic',
																	'strikethrough',
																	'|',
																	'unordered-list',
																	'ordered-list',
																	'quote',
																	'horizontal-rule',
																	'|',
																	'preview',
																	'side-by-side',
																	'fullscreen',
																	'|',
																	'guide',
																],
															}}
														/>
													</FormFieldCell>
													<FormErrorCell meta={meta} isNew={isNew} />
												</tr>
											)}
										</Field>
										{!contentType && (
											<tr>
												<td className={styles.emptyBodyFormFields} span="3">
													Select a content type above...
												</td>
											</tr>
										)}
									</FormTable>
								</Section>
								<Section title="Miscellaneous">
									<FormTable>
										<InfoLastUpdated updated={values.updated} />
										<InfoFirstCreated created={values.created} />
									</FormTable>
								</Section>
							</FormElement>
						</>
					);
				}}
			/>
		</div>
	);
}

export default connectToStore(ContentItemEditor);
