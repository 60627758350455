import React from 'react';
import classNames from 'classnames';
import { connectToStore } from '../store';
import styles from './Section.module.scss';

function Section({ className, bodyClassName, title, children }) {
	const cls = classNames({
		[styles.section]: true,
		[className]: !!className,
	});
	const clsSectionBody = classNames({
		[styles.body]: true,
		[bodyClassName]: !!bodyClassName,
	});

	return (
		<section className={cls}>
			{title && <div className={styles.title}>{title}</div>}
			<div className={clsSectionBody}>{children}</div>
		</section>
	);
}

export default connectToStore(Section);
