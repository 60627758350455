import React, { useState, useEffect } from 'react';
import { useRouteMatch, useHistory } from 'react-router-dom';
import classNames from 'classnames';
import { connectToStore } from '../../../store';
import Highlights from '../../../shared/highlights/Highlights';
import Button from '../../../shared/button/Button';
import Toolbar from '../../../layout/Toolbar';
import { PlusIcon, TableIcon, RefreshIcon } from '../../../shared/icons';
import Table from '../../../shared/table/Table';
import { AgGridColumn } from 'ag-grid-react';
import styles from './UserList.module.scss';

const ID_FIELD_KEY = `patient_id`;

function constructHighlights(state) {
	return [
		{
			colour: `#07BAB4`,
			value: state.userList.rowData.length,
			label: `Users`,
		},
		{
			colour: `#07BAB4`,
			value: state.userList.rowData.filter(row => row.active).length,
			label: `Active Users`,
		},
		{
			colour: `#07BAB4`,
			value: state.userList.rowData.filter(row => row.enterprise_id).length,
			label: `Linked Users`,
		},
		{
			colour: `#07BAB4`,
			value: state.userList.rowData.filter(row => !row.enterprise_id).length,
			label: `Individual Users`,
		},
	];
}

function navigateToItem(history, url, evt) {
	history.push(`${url}/details/${evt.data[ID_FIELD_KEY]}`);
}

function navigateToSubscription(history, evt) {
	if (evt.data.subscription_id) history.push(`/subscriptions/details/${evt.data.subscription_id}`);
}

function navigateToClient(history, evt) {
	if (evt.data.enterprise_id) history.push(`/clients/details/${evt.data.enterprise_id}`);
}

function DataView({
	loading,
	success,
	errorMessage,
	actions,
	rowData,
	tableState,
	setTableCtrl,
	navigateToItemBound,
	navigateToSubscriptionBound,
	navigateToClientBound,
}) {
	if (!loading && !success) {
		return (
			<div className={styles.errorView}>
				<span>Error: {errorMessage || `An unknown error ocurred`}</span>
			</div>
		);
	}

	return (
		<Table
			loading={loading}
			rowData={rowData}
			tableState={tableState}
			onStateChange={tableState => actions.userList.updateTableState(tableState)}
			onTableReady={tableCtrl => setTableCtrl(tableCtrl)}
		>
			<AgGridColumn type="id" field={ID_FIELD_KEY} onCellDoubleClicked={navigateToItemBound}></AgGridColumn>
			<AgGridColumn
				type="navigation"
				headerName="First Name"
				field="first_name"
				width={125}
				onCellDoubleClicked={navigateToItemBound}
			></AgGridColumn>
			<AgGridColumn
				type="navigation"
				headerName="Last Name"
				field="last_name"
				width={125}
				onCellDoubleClicked={navigateToItemBound}
			></AgGridColumn>
			<AgGridColumn type="email" headerName="Email" field="email" minWidth={275} flex={1}></AgGridColumn>
			<AgGridColumn
				headerName="Company"
				field="company"
				width={200}
				onCellDoubleClicked={navigateToClientBound}
			></AgGridColumn>
			<AgGridColumn
				type="subscriptionStatus"
				headerName="Subscription"
				field="subscription_status"
				width={140}
				onCellDoubleClicked={navigateToSubscriptionBound}
			></AgGridColumn>
			<AgGridColumn type="yesno" headerName="Enabled" field="enabled" width={125}></AgGridColumn>
			<AgGridColumn type="yesno" headerName="Active" field="active" width={100}></AgGridColumn>
			<AgGridColumn type="datetime" headerName="Created" field="created" sort="desc"></AgGridColumn>
		</Table>
	);
}

function UserList({ state, actions, className }) {
	const { tableState, loading, success, errorMessage, rowData } = state.userList;
	const highlights = constructHighlights(state);
	const history = useHistory();
	const [tableCtrl, setTableCtrl] = useState(undefined);
	const { url } = useRouteMatch();
	const navigateToItemBound = navigateToItem.bind(undefined, history, url);
	const navigateToSubscriptionBound = navigateToSubscription.bind(undefined, history);
	const navigateToClientBound = navigateToClient.bind(undefined, history);
	const cls = classNames({
		[styles.userList]: true,
		[className]: !!className,
	});
	useEffect(() => actions.userList.loadData(), [actions.userList]);

	return (
		<div className={cls}>
			<Highlights highlights={highlights} />
			<Toolbar>
				<Button to={`${url}/details/new`}>
					<PlusIcon />
					<span>Add User</span>
				</Button>
				<Button onClick={() => actions.userList.loadData()} disabled={loading}>
					<RefreshIcon />
					<span>Refresh</span>
				</Button>
				<Button onClick={() => tableCtrl && tableCtrl.reset()} disabled={loading || !success}>
					<TableIcon />
					<span>Reset Table</span>
				</Button>
			</Toolbar>
			<DataView
				loading={loading}
				success={success}
				errorMessage={errorMessage}
				actions={actions}
				rowData={rowData}
				tableState={tableState}
				setTableCtrl={setTableCtrl}
				navigateToItemBound={navigateToItemBound}
				navigateToSubscriptionBound={navigateToSubscriptionBound}
				navigateToClientBound={navigateToClientBound}
			/>
		</div>
	);
}

export default connectToStore(UserList);
