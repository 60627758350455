import React, { useRef } from 'react';
import classNames from 'classnames';
import { connectToStore } from '../../store';
import Section from '../../layout/Section';
import Button from '../../shared/button/Button';
import FormTable from '../../shared/form-table/FormTable';
import FormLabelCell from '../../shared/form-label-cell/FormLabelCell';
import FormFieldCell from '../../shared/form-field-cell/FormFieldCell';
import FormErrorCell from '../../shared/form-error-cell/FormErrorCell';
import styles from './MyAccount.module.scss';

async function attemptChangePassword(actions, email, newPasswordEl, confirmPasswordEl) {
	const newPassword = newPasswordEl.current.value.trim();
	const confirmPassword = confirmPasswordEl.current.value.trim();

	if (!newPassword) {
		actions.myAccount.receiveErrorResponse(`You must enter a new password`);
		return;
	}

	if (!confirmPassword) {
		actions.myAccount.receiveErrorResponse(`You must confirm your new password`);
		return;
	}

	if (confirmPassword !== newPassword) {
		actions.myAccount.receiveErrorResponse(`Your new passwords don't match`);
		return;
	}

	actions.myAccount.attemptChangePassword(email, newPassword);
}

function MyAccount({ state, actions }) {
	const { email } = state.session;
	const { loading, errorMessage, success } = state.myAccount;
	const newPasswordEl = useRef(``);
	const confirmPasswordEl = useRef(``);
	const executeChangePassword = () => attemptChangePassword(actions, email, newPasswordEl, confirmPasswordEl);
	const resultBoxCls = classNames({
		[styles.resultBox]: true,
		[styles.resultBoxError]: errorMessage,
		[styles.resultBoxSuccess]: success,
		[styles.resultBoxVisible]: errorMessage || success,
	});

	return (
		<div className={styles.screen}>
			<Section title="User Details">
				<div>{email}</div>
			</Section>
			<Section title="Change Password">
				<FormTable>
					<tr>
						<FormLabelCell label="New Password" />
						<FormFieldCell>
							<input ref={newPasswordEl} type="password" disabled={success} />
						</FormFieldCell>
						<FormErrorCell />
					</tr>
					<tr>
						<FormLabelCell label="Confirm Password" />
						<FormFieldCell>
							<input ref={confirmPasswordEl} type="password" disabled={success} />
						</FormFieldCell>
						<FormErrorCell />
					</tr>
				</FormTable>
				<div className={resultBoxCls}>
					{errorMessage && <div>Error: {errorMessage}</div>}
					{success && <div>Success!</div>}
				</div>
				<Button onClick={executeChangePassword} loading={loading} disabled={success} className={styles.button}>
					Change Password
				</Button>
			</Section>
		</div>
	);
}

export default connectToStore(MyAccount);
