import React from 'react';
import classNames from 'classnames';
import Section from '../../../layout/Section';
import { PlusIcon, TableIcon } from '../../../shared/icons';
import { connectToStore } from '../../../store';
import styles from './ReportsList.module.scss';
import Button from '../../../shared/button/Button';
import { useRouteMatch } from 'react-router-dom';

function ReportsList({ state, actions }) {
    const { reports } = state.reports;
    const { url } = useRouteMatch();
    const reportCls = classNames({
        [styles.reportPreview]: true,
        [styles.empty]: false,
    });

    const newReportCls = classNames({
        [styles.reportPreview]: true,
        [styles.empty]: true,
    });

    // useEffect(() => actions.reports.loadData(), [actions.reports]); //get reports from DB

    return (
        <div className={styles.screen}>
            {Object.entries(reports).map(([category, items]) => (
                <Section
                    key={category}
                    title={category}
                    bodyClassName={styles.sectionContainer}
                >
                    {items.map(report => (
                        <div key={report.title} className={styles.report}>
                            <Button className={reportCls}
                                to={`${url}/${category}/${report.title}`}>
                                <TableIcon color={`#d5d9df`} className={`fa-4x`} />
                            </Button>
                            <div title={report.title} className={styles.reportTitle}>{report.title}</div>
                        </div>
                    ))}

                    <div className={styles.report}>
                        <Button
                            className={newReportCls}
                            to={`${url}/${category}/new`}
                        >
                            <PlusIcon color={`#d5d9df`} className={`fa-4x`} />
                            <h5>Add a new report</h5>
                        </Button>
                    </div>

                </Section>
            ))}
            <Button
                className={styles.addCategoryBtn}
                onClick={() => actions.reports.addReportCategory(`New Category ${Object.keys(reports).length}`)}
            >
                <PlusIcon color={`#d5d9df`} className={`fa-lg`} />
                <span>Add a new category</span>
            </Button>
        </div>
    );
}

export default connectToStore(ReportsList);
