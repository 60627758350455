const PRODUCTION = {
	BACKEND_API_URL: `https://api-production.lettheinsideout.com`,
};

const STAGING = {
	BACKEND_API_URL: `https://api-staging.lettheinsideout.com`,
};

const DEVELOPMENT = {
	BACKEND_API_URL: `http://localhost:3000`,
};

const NODE_ENV = process.env.NODE_ENV;
const variables = NODE_ENV === `production` ? PRODUCTION : NODE_ENV === `staging` ? STAGING : DEVELOPMENT;

export default variables;
