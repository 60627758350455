import React from 'react';
import { Provider } from 'react-redux';
import ReactDOM from 'react-dom';
import { BrowserRouter } from 'react-router-dom';
import { store } from './store';
import App from './layout/App';
import 'normalize.css';
import './index.scss';

ReactDOM.render(
	(
		// <React.StrictMode>
			<Provider store={store}>
				<BrowserRouter>
					<App />
				</BrowserRouter>
			</Provider>
		// </React.StrictMode>
	),
	document.getElementById('root'),
);
