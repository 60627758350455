import React from 'react';
import classNames from 'classnames';
import Section from '../../layout/Section';
import styles from './Highlights.module.scss';

function HighlightOrb({ className, colour, value = `?`, label }) {
	const cls = classNames({
		[styles.highlightOrb]: true,
		[className]: !!className,
	});

	return (
		<div className={cls}>
			<div className={styles.highlightOrbCircle} style={{ backgroundColor: colour }}><span>{value}</span></div>
			<div className={styles.highlightOrbLabel}>{label}</div>
		</div>
	);
}

function Highlights({ className, highlights }) {
	const cls = classNames({
		[styles.highlights]: true,
		[className]: !!className,
	});

	return (
		<Section className={cls} bodyClassName={styles.highlightsSectionBody}>
			{highlights.map((highlight, index) =>
				<HighlightOrb key={index} className={highlight.className} colour={highlight.colour} value={highlight.value} label={highlight.label} />)
			}
		</Section>
	);
}

export default Highlights;
