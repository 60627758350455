/**
 * @file Action creators.
 */

import { default as actionTypes } from './actionTypes';
import * as config from '../../config';
import { getRequestHeaders } from '../apiHelpers';

const DEVICE_ID = `INTERNAL_DASHBOARD`;

function startLogInRequest() {
	return {
		type: actionTypes.START_LOG_IN_REQUEST,
		payload: {
			errorMessage: undefined,
			loading: true,
		},
	};
}

export function receiveAccessToken(email, accessToken, refreshToken) {
	const payload = {
		accessToken,
		refreshToken,
		authenticated: true,
		dateLoggedIn: new Date(),
		email,
		errorMessage: undefined,
		loading: false,
	};

	localStorage.setItem(`ins-session-state`, JSON.stringify(payload));

	return {
		type: actionTypes.RECEIVE_ACCESS_TOKEN,
		payload,
	};
}

export function receiveLogInError(errorMessage) {
	return {
		type: actionTypes.RECEIVE_LOG_IN_ERROR,
		payload: {
			authenticated: false,
			accessToken: undefined,
			refreshToken: undefined,
			dateLoggedIn: undefined,
			email: undefined,
			errorMessage,
			loading: false,
		},
	};
}

async function makeLogInRequest(email, password) {
	const body = { email, password, device_id: DEVICE_ID };
	const opts = {
		method: `post`,
		headers: getRequestHeaders(),
		body: JSON.stringify(body),
	};
	return fetch(`${config.variables.BACKEND_API_URL}/authentication/generate`, opts);
}

export function attemptLogIn(email, password) {
	return async dispatch => {
		try {
			dispatch(startLogInRequest());

			const res = await makeLogInRequest(email, password);
			if (res.status !== 200) throw new Error(`Invalid email or password`);

			const body = await res.json();
			if (!body.response.all_user_types.includes(`staff`)) throw new Error(`User does not have the "staff" role`);
			if (!body.response.token) throw new Error(`Invalid server response (missing access token)`);

			dispatch(receiveAccessToken(email, body.response.token, body.response.refresh_token));
		} catch (err) {
			dispatch(receiveLogInError(err.message));
		}
	};
}

export function reset() {
	return async (dispatch, getState) => {
		localStorage.removeItem(`ins-session-state`);

		const state = getState();
		const { accessToken } = state.session;

		const url = `${config.variables.BACKEND_API_URL}/authentication/removeToken?device_id=${DEVICE_ID}`;
		const opts = {
			method: `delete`,
			headers: getRequestHeaders(accessToken),
		};
		fetch(url, opts).catch(err => void err); // Ignore any errors when removing the token.

		dispatch({ type: actionTypes.RESET });
	};
}
