import React from 'react';
import { Switch, Route, useRouteMatch } from 'react-router-dom';
import { connectToStore } from '../../store';
import SubscriptionList from './subscription-list/SubscriptionList';
import SubscriptionDetails from './subscription-details/SubscriptionDetails';
import NotFound from '../not-found/NotFound';
import styles from './Subscriptions.module.scss';

const subRoutes = [
	{
		path: `/`,
		exact: true,
		component: SubscriptionList,
	},
	{
		path: `/details/:id`,
		exact: true,
		component: SubscriptionDetails,
	},
];

function Subscriptions() {
	const { path } = useRouteMatch();

	return (
		<div className={styles.screen}>
			<Switch>
				{/* Routes */}
				{subRoutes.map((route, index) => (
					<Route key={index} path={`${path}${route.path}`} exact={route.exact}>
						<route.component />
					</Route>
				))}

				{/* 404 */}
				<Route path="*">
					<NotFound />
				</Route>
			</Switch>
		</div>
	);
}

export default connectToStore(Subscriptions);
