import React from 'react';
import classNames from 'classnames';
import styles from './FormTable.module.scss';

function FormTable({ className, children }) {
	const cls = classNames({
		[styles.formTable]: true,
		[className]: !!className,
	});

	return (
		<table className={cls}>
			<tbody>{children}</tbody>
		</table>
	);
}

export default FormTable;
