import React from 'react';
import { connectToStore } from '../../store';
import styles from './NotFound.module.scss';

function NotFound() {

	return (
		<div className={styles.screen}>
			<div className={styles.errorMsg}>404 Page Not Found</div>
		</div>
	);
}

export default connectToStore(NotFound);
