import moment from 'moment';

export function compareNumbers(valueA, valueB, nodeA, nodeB, isInverted) {
	return parseFloat(valueA || 0) - parseFloat(valueB || 0);
}

function convertTimeToSeconds(dateTime) {
	const time = dateTime ? moment(dateTime).format(`H:m:s`) : undefined;
	const [hours = 0, minutes = 0, seconds = 0] = time?.split(`:`) || [];
	return parseInt(hours) * 60 * 60 + parseInt(minutes) * 60 + parseInt(seconds);
}

export function compareTimes(valueA, valueB, nodeA, nodeB, isInverted) {
	const valA = convertTimeToSeconds(valueA);
	const valB = convertTimeToSeconds(valueB);
	return valA - valB;
}
