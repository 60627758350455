
export function constructHighlights({ statistics }) {
    return [
        {
            colour: `#07BAB4`,
            value: statistics.statsData.active_users,
            label: `Active Users`,
        },
        {
            colour: `#07BAB4`,
            value: statistics.statsData.active_mhe,
            label: `Active MHE`,
        },
        {
            colour: `#07BAB4`,
            value: statistics.statsData.upcoming_sessions.length,
            label: `Upcoming Sessions`,
        },
    ];
}
