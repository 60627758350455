import React from 'react';
import classNames from 'classnames';
import { AgGridReact } from 'ag-grid-react';
import 'ag-grid-community/dist/styles/ag-grid.css';
import 'ag-grid-community/dist/styles/ag-theme-balham.css';
import styles from './Table.module.scss';
import Loader from '../loader/Loader';
import { formatValueDateTime, formatValueDate, formatValueTime, formatSubscriptionStatus } from './formatters';
import { renderCellUrl, renderCellEmail, renderCellYesNo } from './renderers';
import { compareNumbers, compareTimes } from './comparators';

const COLUMN_TYPES = {
	id: {
		headerName: `ID`,
		field: `id`,
		width: 100,
		minWidth: 100,
		lockPosition: true,
		lockVisible: true,
		pinned: `left`,
		filter: `agNumberColumnFilter`,
		editable: false,
		suppressMovable: true,
		cellClass: styles.idCell,
		comparator: compareNumbers,
	},
	navigation: {
		cellClass: styles.navigationCell,
	},
	center: {
		cellClass: styles.centerCell,
	},
	number: {
		cellClass: styles.centerCell,
		comparator: compareNumbers,
		filter: 'agNumberColumnFilter',
	},
	datetime: {
		width: 130,
		filter: `agDateColumnFilter`,
		valueFormatter: formatValueDateTime,
	},
	date: {
		width: 100,
		filter: `agDateColumnFilter`,
		valueFormatter: formatValueDate,
	},
	time: {
		width: 100,
		valueFormatter: formatValueTime,
		comparator: compareTimes,
	},
	subscriptionStatus: {
		width: 140,
		valueFormatter: formatSubscriptionStatus,
	},
	url: {
		width: 300,
		cellRenderer: renderCellUrl,
	},
	email: {
		width: 300,
		cellRenderer: renderCellEmail,
	},
	yesno: {
		width: 300,
		cellRenderer: renderCellYesNo,
		filterParams: {
			filterOptions: [
				'empty',
				{
					displayKey: 'yes',
					displayName: 'Yes',
					test: (filterValue, cellValue) => cellValue === `true`,
					hideFilterInput: true,
				},
				{
					displayKey: 'no',
					displayName: 'No',
					test: (filterValue, cellValue) => cellValue === `false`,
					hideFilterInput: true,
				},
			],
			suppressAndOrCondition: true,
		},
	},
};

const DEFAULT_COLUMN_DEFINITION = {
	editable: false,
	filter: true,
	resizable: true,
	sortable: true,
	suppressMovable: true,
	unSortIcon: true,
	width: 200,
	minWidth: 75,
};

function getTableState(gridApi) {
	return {
		sortModel: gridApi.getSortModel(),
		filterModel: gridApi.getFilterModel(),
	};
}

function setUserInitialState(gridApi, initialState) {
	if (!initialState) return;
	if (initialState.sortModel) gridApi.setSortModel(initialState.sortModel);
	if (initialState.filterModel) gridApi.setFilterModel(initialState.filterModel);
}

function triggerTableStateChangeHandler(onStateChange, gridApi) {
	const tableState = getTableState(gridApi);
	onStateChange(tableState);
}

function resetTable(gridApi, baseState) {
	gridApi.setSortModel(baseState.sortModel);
	gridApi.setFilterModel(baseState.filterModel);
}

function onGridReady(initialState, onStateChange, onTableReady, gridApi) {
	const baseState = getTableState(gridApi);
	setUserInitialState(gridApi, initialState);

	if (typeof onStateChange === `function`) {
		gridApi.addEventListener(`sortChanged`, () => triggerTableStateChangeHandler(onStateChange, gridApi));
		gridApi.addEventListener(`filterChanged`, () => triggerTableStateChangeHandler(onStateChange, gridApi));
	}

	if (typeof onTableReady === `function`) {
		const tableCtrl = {
			reset: () => resetTable(gridApi, baseState),
		};
		onTableReady(tableCtrl);
	}
}

function Table({
	className,
	themeName = `ag-theme-balham`,
	loading,
	rowData,
	children,
	initialState,
	onStateChange,
	onTableReady,
}) {
	const cls = classNames({
		[styles.tableCmp]: true,
		[themeName]: true,
		[className]: !!className,
	});

	return (
		<div className={cls}>
			{loading && (
				<div className={styles.loadingOverlay}>
					<Loader mode="light" />
				</div>
			)}
			<AgGridReact
				columnTypes={COLUMN_TYPES}
				defaultColDef={DEFAULT_COLUMN_DEFINITION}
				rowData={rowData}
				rowSelection="multiple"
				suppressMenuHide={true}
				animateRows={true}
				onGridReady={params => onGridReady(initialState, onStateChange, onTableReady, params.api)}
			>
				{children}
			</AgGridReact>
		</div>
	);
}

export default Table;
