/**
 * @file Action types.
 */

import { createActionTypes } from '../utilities';

const namespacedActionTypes = createActionTypes(`file-browser`, [
	`UPDATE_TABLE_STATE`,
	`START_LOAD_DATA_REQUEST`,
	`RECEIVE_SUCCESS_RESPONSE`,
	`RECEIVE_ERROR_RESPONSE`,
]);

const globalActionTypes = createActionTypes(undefined, [`RESET`]);

export default {
	...namespacedActionTypes,
	...globalActionTypes,
};
