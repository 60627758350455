/**
 * @file Action types.
 */

import { createActionTypes } from '../utilities';

const namespacedActionTypes = createActionTypes(`reports`, [
	`ADD_REPORT`,
	`ADD_REPORT_CATEGORY`,
	`UPDATE_REPORTS_STATE`,
	`START_LOAD_DATA_REQUEST`,
	`RECEIVE_SUCCESS_RESPONSE`,
	`RECEIVE_ERROR_RESPONSE`,
]);

const globalActionTypes = createActionTypes(undefined, [`RESET`]);

export default {
	...namespacedActionTypes,
	...globalActionTypes,
};
