export function mapStateToProps(state) {
	return { state };
}

export function dispatchAction(dispatch, createActionFn, ...args) {
	const action = createActionFn(...args);
	if (action) dispatch(action);
}

export function mapDispatchToProps(dispatch, actions) {
	const groupEntries = Object.entries(actions);
	const actionFunctions = {};

	groupEntries.forEach(([groupKey, groupActions]) => {
		actionFunctions[groupKey] = Object.entries(groupActions).reduce((acc, [actionName, createActionFn]) => {
			acc[actionName] = (...args) => dispatchAction(dispatch, createActionFn, ...args);
			return acc;
		}, {});
	});

	return { actions: actionFunctions };
}
