/**
 * @file Action creators.
 */

import { default as actionTypes } from './actionTypes';
import * as config from '../../config';
import { getNewTokens, getRequestHeaders } from '../apiHelpers';
import { receiveAccessToken } from '../session/actionCreators';

export function updateTableState(tableState) {
	return {
		type: actionTypes.UPDATE_TABLE_STATE,
		payload: {
			tableState,
		},
	};
}

function startGetContentThemesRequest() {
	return {
		type: actionTypes.START_LOAD_DATA_REQUEST,
		payload: {
			errorMessage: undefined,
			loading: true,
			success: false,
		},
	};
}

function receiveSuccessResponse(rowData) {
	return {
		type: actionTypes.RECEIVE_SUCCESS_RESPONSE,
		payload: {
			rowData,
			errorMessage: undefined,
			loading: false,
			success: true,
		},
	};
}

export function receiveErrorResponse(errorMessage) {
	return {
		type: actionTypes.RECEIVE_ERROR_RESPONSE,
		payload: {
			errorMessage,
			loading: false,
			success: false,
		},
	};
}

async function makeGetRequest(accessToken) {
	const opts = {
		method: `get`,
		headers: getRequestHeaders(accessToken),
	};
	return fetch(`${config.variables.BACKEND_API_URL}/media/content_themes`, opts);
}

export function loadData() {
	return async (dispatch, getState) => {
		try {
			dispatch(startGetContentThemesRequest());

			const state = getState();
			const { accessToken, refreshToken, email } = state.session;
			let res;
			res = await makeGetRequest(accessToken);

			if (res.status === 401) {
				const newTokens = await getNewTokens(accessToken, refreshToken);
				const newTokensObj = await newTokens.json();
				dispatch(receiveAccessToken(email, newTokensObj.response.token, newTokensObj.response.refresh_token));
				res = await makeGetRequest(newTokensObj.response.token);
			}

			if (res.status !== 200) throw new Error(`${res.status} ${res.statusText}`);

			const body = await res.json();

			dispatch(receiveSuccessResponse(body.response));
		} catch (err) {
			dispatch(receiveErrorResponse(err.message));
		}
	};
}

export function reset() {
	return { type: actionTypes.RESET };
}
