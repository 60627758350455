import React, { useState, useEffect } from 'react';
import { useRouteMatch, useHistory } from 'react-router-dom';
import classNames from 'classnames';
import { connectToStore } from '../../../store';
import Button from '../../../shared/button/Button';
import Toolbar from '../../../layout/Toolbar';
import { PlusIcon, TableIcon, RefreshIcon } from '../../../shared/icons';
import Table from '../../../shared/table/Table';
import { AgGridColumn } from 'ag-grid-react';
import styles from './TagsTab.module.scss';

const ID_FIELD_KEY = `content_tag_id`;

function navigateToItem(history, url, evt) {
	history.push(`${url}/tags/${evt.data[ID_FIELD_KEY]}`);
}

function DataView({ loading, success, errorMessage, actions, rowData, tableState, setTableCtrl, navigateToItemBound }) {
	if (!loading && !success) {
		return (
			<div className={styles.errorView}>
				<span>Error: {errorMessage || `An unknown error ocurred`}</span>
			</div>
		);
	}

	return (
		<Table
			loading={loading}
			rowData={rowData}
			tableState={tableState}
			onStateChange={tableState => actions.contentTags.updateTableState(tableState)}
			onTableReady={tableCtrl => setTableCtrl(tableCtrl)}
		>
			<AgGridColumn type="id" field={ID_FIELD_KEY} onCellDoubleClicked={navigateToItemBound}></AgGridColumn>
			<AgGridColumn
				type="navigation"
				headerName="Name"
				field="tag_name"
				sort="asc"
				minWidth={200}
				onCellDoubleClicked={navigateToItemBound}
			></AgGridColumn>
			<AgGridColumn type="datetime" headerName="Created" field="created"></AgGridColumn>
			<AgGridColumn type="datetime" headerName="Updated" field="updated"></AgGridColumn>
		</Table>
	);
}

function TagsTab({ state, actions, className }) {
	const { tableState, loading, success, errorMessage, rowData } = state.contentTags;
	const history = useHistory();
	const [tableCtrl, setTableCtrl] = useState(undefined);
	const { url } = useRouteMatch();
	const navigateToItemBound = navigateToItem.bind(undefined, history, url);
	const cls = classNames({
		[styles.tagsTab]: true,
		[className]: !!className,
	});
	useEffect(() => actions.contentTags.loadData(), [actions.contentTags]);

	return (
		<div className={cls}>
			<Toolbar>
				<Button to={`${url}/tags/new`}>
					<PlusIcon />
					<span>Add Tag</span>
				</Button>
				<Button onClick={() => actions.contentTags.loadData()} disabled={loading}>
					<RefreshIcon />
					<span>Refresh</span>
				</Button>
				<Button onClick={() => tableCtrl && tableCtrl.reset()} disabled={loading || !success}>
					<TableIcon />
					<span>Reset Table</span>
				</Button>
			</Toolbar>
			<DataView
				loading={loading}
				success={success}
				errorMessage={errorMessage}
				actions={actions}
				rowData={rowData}
				tableState={tableState}
				setTableCtrl={setTableCtrl}
				navigateToItemBound={navigateToItemBound}
			/>
		</div>
	);
}

export default connectToStore(TagsTab);
