import React from 'react';
import PropTypes from 'prop-types';

/**
 * This component is a helper component for the TabContainer component and does not render anything itself.
 */

Tab.propTypes = {
	tabId: PropTypes.string.isRequired,
	title: PropTypes.string.isRequired,
	children: PropTypes.node.isRequired,
};

function Tab() {
	return (<></>);
}

export default Tab;
