import React from 'react';
import classNames from 'classnames';
import Loader from '../loader/Loader';
import styles from './FormLoadingOverlay.module.scss';

function FormLoadingOverlay({ className, loading, loaderMode = `light` }) {
	const cls = classNames({
		[styles.formLoadingOverlay]: true,
		[className]: !!className,
	});

	if (!loading) return <></>;

	return (
		<div className={cls}>
			<Loader mode={loaderMode} />
		</div>
	);
}

export default FormLoadingOverlay;
