import React from 'react';
import { Pie } from 'react-chartjs-2';
import 'chartjs-plugin-labels';

const options = {
    plugins: {
        labels: {
            // render 'label', 'value', 'percentage', 'image' or custom function, default is 'percentage'
            render: 'percentage',
            fontColor: '#fff',
            fontSize: 10
        },
    },
    elements: {
        arc: {
            borderWidth: 0
        }
    },
    maintainAspectRatio: false,
    responsive: false,
    aspectRatio: 1,
    legend: {
        position: 'right',
        labels: {
            boxWidth: 12,
            fontColor: '#3C3E49',
            fontSize: 15
        }
    },
    tooltips: {
        callbacks: {
            label: function (tooltipItem, data) {
                const dataset = data.datasets[tooltipItem.datasetIndex];
                const meta = dataset._meta[Object.keys(dataset._meta)[0]];
                const total = meta.total;
                const currentValue = dataset.data[tooltipItem.index];
                const percentage = parseFloat((currentValue / total * 100).toFixed(1));
                return currentValue + ' (' + percentage + '%)';
            },
            title: function (tooltipItem, data) {
                return data.labels[tooltipItem[0].index];
            }
        }
    }
};

export default function PieChart(props) {
    const total = props.data && props.data.reduce((accumulator, currentValue) => accumulator + currentValue);
    const data = () => {
        return {
            labels: props.data && props.data.map((value, index) => `${props.labels[index]} (${Math.round((value / total) * 100)}%)`),
            datasets: [{
                data: props.data,
                backgroundColor: props.colors,
                hoverBackgroundColor: props.hoverColors,
            }]
        };
    }

    return (
        <Pie data={data}
            options={options}
            height={props.height || 350}
            width={props.width || 450}
        />
    );
};
