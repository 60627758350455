import React, { useEffect, useState, useRef } from 'react';
// import Section from '../../../layout/Section';
// import { PlusIcon, RefreshIcon } from '../../../shared/icons';
import { connectToStore } from '../../../store';
import styles from './ReportEditor.module.scss';
import Button from '../../../shared/button/Button';
import { useParams } from 'react-router-dom';
import Toolbar from '../../../layout/Toolbar';
import { LeftArrowIcon, SaveIcon } from '../../../shared/icons';
import FlexSpace from '../../../layout/FlexSpace';
import { AgGridColumn } from 'ag-grid-react/lib/agGridColumn';
import Table from '../../../shared/table/Table';
import { CSVLink } from "react-csv";


function DataView({ loading, success, errorMessage, rowData, selectedDate }) {
    if (!loading && !success) {
        return (
            <div className={styles.errorView}>
                <span>Error: {errorMessage || `An unknown error ocurred`}</span>
            </div>
        );
    }

    return (
        <Table
            className={styles.screen}
            loading={loading}
            rowData={rowData}
        >
            <AgGridColumn
                type="navigation"
                headerName="Enterprise Name"
                field="enterprise_name"
                tooltipField="enterprise_name"
                minWidth={200}
                flex={1}
                sort="asc"
            ></AgGridColumn>
            <AgGridColumn type="datetime" headerName="Start Date" field="start_date" hide={!!selectedDate} ></AgGridColumn>
            <AgGridColumn type="number" headerName="Initial Sessions" field="initial_sessions"></AgGridColumn>
            <AgGridColumn type="number" headerName="Additional Sessions" field="additional_sessions" ></AgGridColumn>
            <AgGridColumn type="number" headerName="Remaining Sessions" field="remaining_sessions" ></AgGridColumn>
            <AgGridColumn type="number" headerName="Total Used Sessions" field="total_used_sessions" ></AgGridColumn>
            <AgGridColumn type="number" headerName="Total Missed Sessions" field="total_missed_sessions" ></AgGridColumn>
            <AgGridColumn type="number" headerName="Held Regular Sessions" field="held_regular_sessions" ></AgGridColumn>
            <AgGridColumn type="number" headerName="Missed Regular Sessions" field="missed_regular_sessions" ></AgGridColumn>
            <AgGridColumn type="number" headerName="Cancelled Regular Sessions" field="cancelled_regular_sessions" ></AgGridColumn>
            <AgGridColumn type="number" headerName="Held Consultation Sessions" field="held_consultation_sessions" ></AgGridColumn>
            <AgGridColumn type="number" headerName="Missed Consultation Sessions" field="missed_consultation_sessions" ></AgGridColumn>
            <AgGridColumn type="number" headerName="Cancelled Consultation Sessions" field="cancelled_consultation_sessions" ></AgGridColumn>
            <AgGridColumn type="number" headerName="Total Employees" field="total_employees" hide={!!selectedDate} ></AgGridColumn>
            <AgGridColumn type="number" headerName="Active Employees" field="active_employees" ></AgGridColumn>
        </Table>
    );
}

function ReportEditor({ state, actions }) {
    const { reportsState, loading, success, errorMessage } = state.reports;
    const [reportTitle, setReportTitle] = useState(reportsState.title);
    const [selectedDate, setSelectedDate] = useState();
    const [displayError, setDisplayError] = useState();
    const inputDate = useRef(null);
    const { category, id } = useParams();
    useEffect(() => {
        // actions.reportEditor.loadReport(id);
        actions.reports.loadData();
    }, [actions.reports]);

    return (
        <div className={styles.screen}>
            <Toolbar>
                <Button onClick={() => window.history.go(-1)} >
                    <LeftArrowIcon />
                    <span>Back</span>
                </Button>
                <FlexSpace />
                <Button onClick={() => { }}>
                    <CSVLink
                        filename={`${selectedDate || 'all_time'}_report`}
                        data={reportsState.data || []}>Download </CSVLink>
                </Button>
                <Button onClick={() => {
                    if (reportTitle && reportTitle !== ``) {
                        // if (id !== 'new') {
                        //     actions.reports.updateReport({
                        //         category,
                        //         report: {
                        //             title: reportTitle,
                        //             selectedDate,
                        //             data: reportsState.data
                        //         }
                        //     });
                        //     // save to DB
                        // } else {
                        const report = {
                            id: id !== 'new' ? id : 'test',
                            title: reportTitle,
                            selectedDate,
                            data: reportsState.data
                        };
                        actions.reports.addReport(
                            category,
                            report
                        );
                        // save to DB
                        // }
                        window.history.go(-1);
                    } else {
                        setDisplayError(`Please fill in the title field!`);
                    }
                }
                } >
                    <SaveIcon />
                    <span>Save</span>
                </Button>
            </Toolbar>
            <div className={styles.errorMsg}>
                <span>{displayError}</span>
            </div>
            <div className={styles.row}>
                <input type="text" defaultValue={reportTitle} onChange={
                    evt => {
                        setReportTitle(evt.target.value);
                    }
                } />
                <div>
                    <Button
                        onClick={() => {
                            setSelectedDate(null);
                            inputDate.current.value = null;
                            actions.reports.loadData();
                        }}
                    >
                        <span>All Time</span>
                    </Button>
                    <label htmlFor="month">Select period:</label>
                    <input ref={inputDate} id="month" type="month" placeholder="All Time" defaultValue={selectedDate} onChange={
                        evt => {
                            setSelectedDate(evt.target.value);
                            const date = evt.target.value.split('-');
                            const [year, month] = [...date];
                            actions.reports.loadData({ month, year });
                        }
                    } />
                </div>
            </div>
            <DataView
                loading={loading}
                success={success}
                errorMessage={errorMessage}
                actions={actions}
                rowData={reportsState.data}
                selectedDate={selectedDate}
            />
        </div>
    );
}

export default connectToStore(ReportEditor);
