import React, { useState, useEffect } from 'react';
import { useRouteMatch, useHistory } from 'react-router-dom';
import classNames from 'classnames';
import { connectToStore } from '../../../store';
import Highlights from '../../../shared/highlights/Highlights';
import Button from '../../../shared/button/Button';
import Toolbar from '../../../layout/Toolbar';
import { PlusIcon, TableIcon, RefreshIcon } from '../../../shared/icons';
import Table from '../../../shared/table/Table';
import { AgGridColumn } from 'ag-grid-react';
import styles from './SessionList.module.scss';

const ID_FIELD_KEY = `session_id`;

function constructHighlights(state) {
	return [
		{
			colour: `#07BAB4`,
			value: state.sessionList.rowData.length,
			label: `Total Sessions`,
		},
		{
			colour: `#07BAB4`,
			value: state.sessionList.rowData.filter(row => row.session_type === `CONSULTATION`).length,
			label: `Consultations`,
		},
		{
			colour: `#07BAB4`,
			value: state.sessionList.rowData.filter(row => row.session_type === `FUTURE`).length,
			label: `Upcoming`,
		},
	];
}

function navigateToItem(history, url, evt) {
	history.push(`${url}/details/${evt.data[ID_FIELD_KEY]}`);
}

function navigateToUser(history, evt) {
	history.push(`/users/details/${evt.data.patient_id}`);
}

function navigateToExpert(history, evt) {
	history.push(`/experts/details/${evt.data.therapist_id}`);
}

function navigateToSubscription(history, evt) {
	history.push(`/subscriptions/details/${evt.data.subscription_id}`);
}

function DataView({
	loading,
	success,
	errorMessage,
	actions,
	rowData,
	tableState,
	setTableCtrl,
	navigateToItemBound,
	navigateToUserBound,
	navigateToExpertBound,
	navigateToSubscriptionBound,
}) {
	if (!loading && !success) {
		return (
			<div className={styles.errorView}>
				<span>Error: {errorMessage || `An unknown error ocurred`}</span>
			</div>
		);
	}

	return (
		<Table
			loading={loading}
			rowData={rowData}
			tableState={tableState}
			onStateChange={tableState => actions.sessionList.updateTableState(tableState)}
			onTableReady={tableCtrl => setTableCtrl(tableCtrl)}
		>
			<AgGridColumn type="id" field={ID_FIELD_KEY} onCellDoubleClicked={navigateToItemBound}></AgGridColumn>
			<AgGridColumn
				type="navigation"
				headerName="Type"
				field="session_type"
				tooltipField="session_type"
				width={120}
				onCellDoubleClicked={navigateToItemBound}
			></AgGridColumn>
			<AgGridColumn
				type="navigation"
				headerName="Status"
				field="session_status"
				tooltipField="session_status"
				width={100}
				onCellDoubleClicked={navigateToItemBound}
			></AgGridColumn>
			<AgGridColumn
				headerName="User"
				field="patient_full_name"
				tooltipField="patient_full_name"
				minWidth={150}
				flex={1}
				onCellDoubleClicked={navigateToUserBound}
			></AgGridColumn>
			<AgGridColumn
				headerName="Expert"
				field="therapist_full_name"
				tooltipField="therapist_full_name"
				minWidth={150}
				flex={1}
				onCellDoubleClicked={navigateToExpertBound}
			></AgGridColumn>
			<AgGridColumn type="date" headerName="Date" field="start_time"></AgGridColumn>
			<AgGridColumn type="time" headerName="Start" field="start_time"></AgGridColumn>
			<AgGridColumn type="time" headerName="End" field="end_time" sort="desc"></AgGridColumn>
			<AgGridColumn headerName="Attended" field="attended" tooltipField="attended" width={140}></AgGridColumn>
			<AgGridColumn
				type="number"
				headerName="User Rating"
				field="user_rating"
				tooltipField="user_rating"
				width={150}
			></AgGridColumn>
			<AgGridColumn
				headerName="Subscription"
				field="subscription_owner"
				tooltipField="subscription_owner"
				width={150}
				onCellDoubleClicked={navigateToSubscriptionBound}
			></AgGridColumn>
		</Table>
	);
}

function SessionList({ state, actions, className }) {
	const { tableState, loading, success, errorMessage, rowData } = state.sessionList;
	const highlights = constructHighlights(state);
	const history = useHistory();
	const [tableCtrl, setTableCtrl] = useState(undefined);
	const { url } = useRouteMatch();
	const navigateToItemBound = navigateToItem.bind(undefined, history, url);
	const navigateToUserBound = navigateToUser.bind(undefined, history);
	const navigateToExpertBound = navigateToExpert.bind(undefined, history);
	const navigateToSubscriptionBound = navigateToSubscription.bind(undefined, history);
	const cls = classNames({
		[styles.sessionList]: true,
		[className]: !!className,
	});
	useEffect(() => actions.sessionList.loadData(), [actions.sessionList]);

	return (
		<div className={cls}>
			<Highlights highlights={highlights} />
			<Toolbar>
				<Button to={`${url}/details/new`}>
					<PlusIcon />
					<span>Add Session</span>
				</Button>
				<Button onClick={() => actions.sessionList.loadData()} disabled={loading}>
					<RefreshIcon />
					<span>Refresh</span>
				</Button>
				<Button onClick={() => tableCtrl && tableCtrl.reset()} disabled={loading || !success}>
					<TableIcon />
					<span>Reset Table</span>
				</Button>
			</Toolbar>
			<DataView
				loading={loading}
				success={success}
				errorMessage={errorMessage}
				actions={actions}
				rowData={rowData}
				tableState={tableState}
				setTableCtrl={setTableCtrl}
				navigateToItemBound={navigateToItemBound}
				navigateToUserBound={navigateToUserBound}
				navigateToExpertBound={navigateToExpertBound}
				navigateToSubscriptionBound={navigateToSubscriptionBound}
			/>
		</div>
	);
}

export default connectToStore(SessionList);
