import React from 'react';
import { Bar } from 'react-chartjs-2';

const options = {
	plugins: {
		labels: false
	},
	maintainAspectRatio: false,
	legend: {
		display: true,
	},
	scales: {
		xAxes: [{
			stacked: true,
			gridLines: {
				color: `#888888`,
				display: false,
				offsetGridLines: true,
			},
			scaleLabel: {
				display: true,
				labelString: `Days`,
			},
			ticks: {
				fontColor: `#888888`,
			},
		}],
		yAxes: [{
			stacked: true,
			gridLines: {
				color: `#888888`,
				display: false,
			},
			scaleLabel: {
				display: true,
				labelString: `# Sessions`,
			},
			ticks: {
				fontColor: `#888888`,
				min: 0,
				beginAtZero: true,
				precision: 0,
			},
		}],
	},
};

const chartData = data => {
	const numberPerDay = data.map(item => item.regular)
	const numberConsultationPerDay = data.map(item => item.consultation)

	return {
		labels: data.map(item => item.name),
		datasets: [
			{
				label: `# of consultation sessions`,
				data: numberConsultationPerDay,
				backgroundColor: `#00BAB4`,
			},
			{
				label: `# of ordinary sessions`,
				data: numberPerDay,
				backgroundColor: `#016077`,
			},
		],
	};
}

function AppointmentsChart(props) {
	return [
		<Bar
			key="chart"
			data={() => chartData(props.data)}
			height={450}
			options={options} />,
	];
}

export default AppointmentsChart;
