import React from 'react';
import Table from '../../../shared/table/Table';
import { AgGridColumn } from 'ag-grid-react';
import styles from '../Statistics.module.scss';

const ID_FIELD_KEY = `booking_id`;
const USER_FIELD_KEY = `patient_id`;
const EXPERT_FIELD_KEY = `therapist_id`;

export function navigateToUser(history, evt) {
	history.push(`/users/details/${evt.data[USER_FIELD_KEY]}`);
}

export function navigateToExpert(history, evt) {
	history.push(`/experts/details/${evt.data[EXPERT_FIELD_KEY]}`);
}

export function DataView({
	loading,
	success,
	errorMessage,
	actions,
	rowData,
	tableState,
	setTableCtrl,
	navigateToUserBound,
	navigateToExpertBound,
}) {
	if (!loading && !success) {
		return (
			<div className={styles.errorView}>
				<span>Error: {errorMessage || `An unknown error ocurred`}</span>
			</div>
		);
	}
	return (
		<Table
			className={styles.tableCls}
			loading={loading}
			rowData={rowData}
			tableState={tableState}
			onStateChange={tableState => actions.statistics.updateTableState(tableState)}
			onTableReady={tableCtrl => setTableCtrl(tableCtrl)}
		>
			<AgGridColumn sortable={false} type="id" field={ID_FIELD_KEY}></AgGridColumn>
			<AgGridColumn
				sortable={false}
				type="navigation"
				headerName="Patient Name"
				field="patient_name"
				tooltipField="patient_name"
				minWidth={200}
				flex={1}
				onCellDoubleClicked={navigateToUserBound}
			></AgGridColumn>
			<AgGridColumn
				sortable={false}
				type="navigation"
				headerName="Therapist Name"
				field="therapist_name"
				tooltipField="therapist_name"
				minWidth={200}
				flex={1}
				onCellDoubleClicked={navigateToExpertBound}
			></AgGridColumn>
			<AgGridColumn sortable={false} type="datetime" headerName="Date" field="accepted_slot" sort="asc"></AgGridColumn>
			<AgGridColumn
				sortable={false}
				type="center"
				headerName="Session Number"
				field="num_sessions"
				width={140}
			></AgGridColumn>
		</Table>
	);
}
