import React from 'react';
import classNames from 'classnames';
import { connectToStore } from '../store';
import styles from './FlexSpace.module.scss';

function FlexSpace({ className }) {
	const cls = classNames({
		[styles.flexSpace]: true,
		[className]: !!className,
	});

	return (
		<div className={cls}></div>
	);
}

export default connectToStore(FlexSpace);
