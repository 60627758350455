import React from 'react';
import { Link } from 'react-router-dom';
import classNames from 'classnames';
import Loader from '../loader/Loader';
import styles from './Button.module.scss';

function LinkButton({ cls, to, children }) {
	return (
		<Link to={to} className={cls}>
			{children}
		</Link>
	);
}

function JsButton({ cls, onClick, children }) {
	return (
		<div className={cls} onClick={onClick} role="button">
			{children}
		</div>
	);
}

function Button({ className, to, onClick, loading, disabled, children }) {
	const cls = classNames({
		[styles.button]: true,
		[styles.buttonDisabled]: disabled || loading,
		[className]: !!className,
	});
	const clsChildren = classNames({
		[styles.children]: true,
		[styles.childrenVisible]: !loading,
	});
	const clsLoader = classNames({
		[styles.loader]: true,
		[styles.loaderVisible]: loading,
	});

	const innerChildren = (
		<>
			<span className={clsChildren}>{children}</span>
			<Loader className={clsLoader} />
		</>
	);

	if (to) {
		return (
			<LinkButton cls={cls} to={to}>
				{innerChildren}
			</LinkButton>
		);
	} else if (onClick) {
		return (
			<JsButton cls={cls} onClick={onClick}>
				{innerChildren}
			</JsButton>
		);
	} else {
		throw new Error(`Buttons require either "to" or "onClick" prop`);
	}
}

export default Button;
