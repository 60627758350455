import React from 'react';
import classNames from 'classnames';
import styles from './Loader.module.scss';

function Loader({ className, mode = `light` }) {
	const cls = classNames({
		[styles.loader]: true,
		[styles.light]: mode === `light`,
		[styles.dark]: mode === `dark`,
		[className]: !!className,
	});

	return (
		<div className={cls} data-testid="loader"></div>
	);
}

export default Loader;
