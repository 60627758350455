/**
 * @file Action creators.
 */

import { default as actionTypes } from './actionTypes';
import * as config from '../../config';
import { getNewTokens, getRequestHeaders } from '../apiHelpers';
import { receiveAccessToken } from '../session/actionCreators';

function startLoadReportRequest() {
	return {
		type: actionTypes.START_LOAD_REPORT_REQUEST,
		payload: {
			operation: `load`,
			errorMessage: undefined,
			loading: true,
			success: false,
		},
	};
}

function startSaveReportRequest() {
	return {
		type: actionTypes.START_SAVE_REPORT_REQUEST,
		payload: {
			operation: `save`,
			errorMessage: undefined,
			loading: true,
			success: false,
		},
	};
}

function receiveLoadReportSuccessResponse(reportData) {
	return {
		type: actionTypes.RECEIVE_SUCCESS_RESPONSE,
		payload: {
			operation: undefined,
			reportData: {
				...reportData,
			},
			errorMessage: undefined,
			loading: false,
			success: true,
		},
	};
}

function receiveSaveReportSuccessResponse(originalReportData, changes) {
	const reportData = { ...originalReportData, ...changes };

	return {
		type: actionTypes.RECEIVE_SUCCESS_RESPONSE,
		payload: {
			operation: undefined,
			reportData: {
				...reportData,
			},
			errorMessage: undefined,
			loading: false,
			success: true,
			lastSaved: Date.now(),
		},
	};
}

export function receiveErrorResponse(errorMessage) {
	return {
		type: actionTypes.RECEIVE_ERROR_RESPONSE,
		payload: {
			errorMessage,
			loading: false,
			success: false,
		},
	};
}

async function makeLoadReportRequest(accessToken, reportId) {
	const opts = {
		method: `get`,
		headers: getRequestHeaders(accessToken),
	};
	return fetch(`${config.variables.BACKEND_API_URL}/statistics/reports/${reportId}`, opts);
}

export function loadReport(reportId) {
	return async (dispatch, getState) => {
		try {
			if (reportId === `new`) {
				dispatch(receiveLoadReportSuccessResponse({}));
				return;
			}

			dispatch(startLoadReportRequest());

			const state = getState();
			const { accessToken, refreshToken, email } = state.session;
			let res;

			res = await makeLoadReportRequest(accessToken, reportId);

			if (res.status === 401) {
				const newTokens = await getNewTokens(accessToken, refreshToken);
				const newTokensObj = await newTokens.json();
				dispatch(receiveAccessToken(email, newTokensObj.response.token, newTokensObj.response.refresh_token));
				res = await makeLoadReportRequest(newTokensObj.response.token, reportId);
			}

			if (res.status !== 200) throw new Error(`${res.status} ${res.statusText}`);

			const body = await res.json();

			dispatch(receiveLoadReportSuccessResponse(body.response));
		} catch (err) {
			dispatch(receiveErrorResponse(err.message));
		}
	};
}

async function makeSaveReportRequest(accessToken, reportData) {
	const opts = {
		method: `post`,
		headers: getRequestHeaders(accessToken),
		body: JSON.stringify(reportData),
	};
	const reportId = reportData.report_id || ``;
	return fetch(`${config.variables.BACKEND_API_URL}/statistics/reports/${reportId}`, opts);
}

export function saveReport(formValues) {
	return async (dispatch, getState) => {
		try {
			dispatch(startSaveReportRequest());

			const state = getState();
			const { accessToken, refreshToken, email } = state.session;
			let res;

			const reportData = {
				...state.reportEditor.reportData,
				...formValues,
			};

			res = await makeSaveReportRequest(accessToken, reportData);

			if (res.status === 401) {
				const newTokens = await getNewTokens(accessToken, refreshToken);
				const newTokensObj = await newTokens.json();
				dispatch(receiveAccessToken(email, newTokensObj.response.token, newTokensObj.response.refresh_token));
				res = await makeSaveReportRequest(newTokensObj.response.token, reportData);
			}

			if (res.status !== 200) throw new Error(`${res.status} ${res.statusText}`);

			const body = await res.json();

			dispatch(receiveSaveReportSuccessResponse(reportData, body.response));
		} catch (err) {
			dispatch(receiveErrorResponse(err.message));
		}
	};
}

export function resetReportData() {
	return { type: actionTypes.RESET_REPORT_DATA };
}

export function reset() {
	return { type: actionTypes.RESET };
}

export function updateReport(category, report) {
	return {
		type: actionTypes.UPDATE_REPORT,
		payload: { category, report },
	};
}
