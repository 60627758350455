import React from 'react';
import { Link } from 'react-router-dom';
import classNames from 'classnames';
import styles from './TabButton.module.scss';

function TabButton({ className, tabId, url, label, selected, queryParamName = `tabId` }) {
	const cls = classNames({
		[styles.tabButton]: true,
		[styles.tabButtonSelected]: selected,
		[className]: !!className,
	});

	return (
		<Link className={cls} to={`${url}?${queryParamName}=${tabId}`}>
			<span>{label}</span>
		</Link>
	);
}

export default TabButton;
