import React, { useState, useEffect } from 'react';
import { useRouteMatch, useHistory } from 'react-router-dom';
import classNames from 'classnames';
import { connectToStore } from '../../../store';
import Highlights from '../../../shared/highlights/Highlights';
import Button from '../../../shared/button/Button';
import Toolbar from '../../../layout/Toolbar';
import { PlusIcon, TableIcon, RefreshIcon } from '../../../shared/icons';
import Table from '../../../shared/table/Table';
import { AgGridColumn } from 'ag-grid-react';
import styles from './SubscriptionList.module.scss';

const ID_FIELD_KEY = `id`;

function constructHighlights(state) {
	return [
		{
			colour: `#07BAB4`,
			value: state.subscriptionList.rowData.length,
			label: `Total Subscriptions`,
		},
		{
			colour: `#07BAB4`,
			value: state.subscriptionList.rowData.filter(row => row.subscription_status === `ACTIVE`).length,
			label: `Active Subscriptions`,
		},
		{
			colour: `#07BAB4`,
			value: state.subscriptionList.rowData.filter(row => row.subscription_status === `USED`).length,
			label: `Used Subscriptions`,
		},
		{
			colour: `#07BAB4`,
			value: state.subscriptionList.rowData.filter(row => row.subscription_status === `EXPIRED`).length,
			label: `Expired Subscriptions`,
		},
	];
}

function navigateToItem(history, url, evt) {
	history.push(`${url}/details/${evt.data[ID_FIELD_KEY]}`);
}

function navigateToOwner(history, evt) {
	let ownerPath;
	let ownerId;

	if (evt.data.patient_id) {
		ownerPath = `users`;
		ownerId = evt.data.patient_id;
	} else {
		ownerPath = `clients`;
		ownerId = evt.data.enterprise_id;
	}

	history.push(`/${ownerPath}/details/${ownerId}`);
}

function DataView({
	loading,
	success,
	errorMessage,
	actions,
	rowData,
	tableState,
	setTableCtrl,
	navigateToItemBound,
	navigateToOwnerBound,
}) {
	if (!loading && !success) {
		return (
			<div className={styles.errorView}>
				<span>Error: {errorMessage || `An unknown error ocurred`}</span>
			</div>
		);
	}

	return (
		<Table
			loading={loading}
			rowData={rowData}
			tableState={tableState}
			onStateChange={tableState => actions.subscriptionList.updateTableState(tableState)}
			onTableReady={tableCtrl => setTableCtrl(tableCtrl)}
		>
			<AgGridColumn type="id" field={ID_FIELD_KEY} onCellDoubleClicked={navigateToItemBound}></AgGridColumn>
			<AgGridColumn
				type="navigation"
				headerName="Title"
				field="subscription_title"
				tooltipField="subscription_title"
				minWidth={180}
				flex={1}
				onCellDoubleClicked={navigateToItemBound}
			></AgGridColumn>
			<AgGridColumn
				type="navigation"
				headerName="Type"
				field="subscription_type"
				tooltipField="subscription_type"
				width={100}
				onCellDoubleClicked={navigateToItemBound}
			></AgGridColumn>
			<AgGridColumn
				headerName="Owner"
				field="owner"
				tooltipField="owner"
				minWidth={100}
				onCellDoubleClicked={navigateToOwnerBound}
			></AgGridColumn>
			<AgGridColumn type="datetime" headerName="Start" field="start_date"></AgGridColumn>
			<AgGridColumn type="datetime" headerName="End" field="end_date"></AgGridColumn>
			<AgGridColumn
				type="subscriptionStatus"
				headerName="Status"
				field="subscription_status"
				width={140}
			></AgGridColumn>
			<AgGridColumn type="number" headerName="Initial Sess." field="initial_session_qty" width={160}></AgGridColumn>
			<AgGridColumn
				type="number"
				headerName="Additional Sess."
				field="additional_session_qty"
				width={160}
			></AgGridColumn>
			<AgGridColumn type="number" headerName="Sess. Used" field="used_session_qty" width={160}></AgGridColumn>
			<AgGridColumn type="yesno" headerName="Therapy" field="feature_therapy" width={120}></AgGridColumn>
			<AgGridColumn type="yesno" headerName="Toolkit" field="feature_toolkit" width={120}></AgGridColumn>
			<AgGridColumn type="datetime" headerName="Created" field="created" sort="desc"></AgGridColumn>
		</Table>
	);
}

function SubscriptionList({ state, actions, className }) {
	const { tableState, loading, success, errorMessage, rowData } = state.subscriptionList;
	const highlights = constructHighlights(state);
	const history = useHistory();
	const [tableCtrl, setTableCtrl] = useState(undefined);
	const { url } = useRouteMatch();
	const navigateToItemBound = navigateToItem.bind(undefined, history, url);
	const navigateToOwnerBound = navigateToOwner.bind(undefined, history);
	const cls = classNames({
		[styles.subscriptionList]: true,
		[className]: !!className,
	});
	useEffect(() => actions.subscriptionList.loadData(), [actions.subscriptionList]);

	return (
		<div className={cls}>
			<Highlights highlights={highlights} />
			<Toolbar>
				<Button to={`${url}/details/new`}>
					<PlusIcon />
					<span>Add Subscription</span>
				</Button>
				<Button onClick={() => actions.subscriptionList.loadData()} disabled={loading}>
					<RefreshIcon />
					<span>Refresh</span>
				</Button>
				<Button onClick={() => tableCtrl && tableCtrl.reset()} disabled={loading || !success}>
					<TableIcon />
					<span>Reset Table</span>
				</Button>
			</Toolbar>
			<DataView
				loading={loading}
				success={success}
				errorMessage={errorMessage}
				actions={actions}
				rowData={rowData}
				tableState={tableState}
				setTableCtrl={setTableCtrl}
				navigateToItemBound={navigateToItemBound}
				navigateToOwnerBound={navigateToOwnerBound}
			/>
		</div>
	);
}

export default connectToStore(SubscriptionList);
