/**
 * @file Various utility functions for store and reducers.
 */

export function shallowUpdateState(state, payload) {
	return {
		...state,
		...payload,
	};
}

export function createActionTypes(namespace = `global`, types) {
	return types.reduce((acc, key) => {
		if (acc[key]) throw new Error(`Type "${key}" has been defined more than once`);
		const isGlobal = [`global`, undefined].includes(namespace);
		acc[key] = `${isGlobal ? `` : `${namespace}/`}${key}`;
		return acc;
	}, {});
}

export function convertArrayOfIdsToSelectInput(array) {
	return array?.map?.(item => ({ value: item.value ?? item })) ?? [];
}

export function convertSelectInputToArrayOfIds(selectInput) {
	return selectInput?.map(item => item.value) ?? [];
}

export function convertSelectInputToSingleValue(selectInput) {
	return selectInput?.value ?? selectInput;
}

export function convertObjectToJson(object) {
	try {
		if (!object) return ``;
		return JSON.stringify(object, undefined, 2);
	} catch (err) {
		return ``;
	}
}

export function convertJsonToObject(json) {
	try {
		if (!json) return {};
		return JSON.parse(json);
	} catch (err) {
		return {};
	}
}
