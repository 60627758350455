import React from 'react';
import classNames from 'classnames';
import Tab from './Tab';
import TabButton from './TabButton';
import TabItem from './TabItem';
import styles from './TabContainer.module.scss';

function TabContainer({ className, url, selectedTabId, defaultTabId, queryParamName, children }) {
	const cls = classNames({
		[styles.tabContainer]: true,
		[className]: !!className,
	});

	const tabButtons = [];
	const tabItems = [];

	children.forEach((tab, index) => {
		if (tab.type !== Tab) throw new Error(`All direct children of a tab component must be tabs`);

		const { tabId, title, children: tabChildren } = tab.props;
		const selected = tabId === selectedTabId || (!selectedTabId && tabId === defaultTabId);

		tabButtons.push(<TabButton key={index} tabId={tabId} url={url} label={title} selected={selected} queryParamName={queryParamName} />);
		tabItems.push(<TabItem key={index} selected={selected}>{tabChildren}</TabItem>);
	});

	return (
		<div className={cls}>
			<div className={styles.tabBar}>
				{tabButtons}
			</div>
			<div className={styles.tabBody}>
				{tabItems}
			</div>
		</div>
	);
}

export default TabContainer;
