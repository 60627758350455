import { combineReducers, createStore, applyMiddleware } from 'redux';
import { connect } from 'react-redux';
import thunkMiddleware from 'redux-thunk';
import { mapStateToProps, mapDispatchToProps } from './mappers';
import clientList from './client-list';
import contentItems from './content-items';
import contentItemEditor from './content-item-editor';
import contentTags from './content-tags';
import contentTagEditor from './content-tag-editor';
import contentThemes from './content-themes';
import contentThemeEditor from './content-theme-editor';
import expertList from './expert-list';
import myAccount from './my-account';
import session from './session';
import sessionList from './session-list';
import subscriptionList from './subscription-list';
import userList from './user-list';
import statistics from './statistics';
import fileBrowser from './file-browser';
import reports from './reports';
import reportEditor from './report-editor';

const rootReducer = combineReducers({
	clientList: clientList.reducer,
	contentItems: contentItems.reducer,
	contentItemEditor: contentItemEditor.reducer,
	contentTags: contentTags.reducer,
	contentTagEditor: contentTagEditor.reducer,
	contentThemes: contentThemes.reducer,
	contentThemeEditor: contentThemeEditor.reducer,
	expertList: expertList.reducer,
	myAccount: myAccount.reducer,
	session: session.reducer,
	sessionList: sessionList.reducer,
	subscriptionList: subscriptionList.reducer,
	userList: userList.reducer,
	statistics: statistics.reducer,
	fileBrowser: fileBrowser.reducer,
	reports: reports.reducer,
	reportEditor: reportEditor.reducer,
});

export const actions = {
	clientList: clientList.actionCreators,
	contentItems: contentItems.actionCreators,
	contentItemEditor: contentItemEditor.actionCreators,
	contentTags: contentTags.actionCreators,
	contentTagEditor: contentTagEditor.actionCreators,
	contentThemes: contentThemes.actionCreators,
	contentThemeEditor: contentThemeEditor.actionCreators,
	expertList: expertList.actionCreators,
	myAccount: myAccount.actionCreators,
	session: session.actionCreators,
	sessionList: sessionList.actionCreators,
	subscriptionList: subscriptionList.actionCreators,
	userList: userList.actionCreators,
	statistics: statistics.actionCreators,
	fileBrowser: fileBrowser.actionCreators,
	reports: reports.actionCreators,
	reportEditor: reportEditor.actionCreators,
};

export function connectToStore(Component) {
	const wrap = connect(
		state => mapStateToProps(state),
		dispatch => mapDispatchToProps(dispatch, actions),
		undefined,
		{ forwardRef: true }, // Must be supplied for react/redux when using AgGridReact.
	);
	return wrap(Component);
}

export const store = createStore(rootReducer, applyMiddleware(thunkMiddleware));
