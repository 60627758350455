/**
 * @file Action creators.
 */

import { default as actionTypes } from './actionTypes';
import * as config from '../../config';
import { getNewTokens, getRequestHeaders } from '../apiHelpers';
import { receiveAccessToken } from '../session/actionCreators';

function startChangePasswordRequest() {
	return {
		type: actionTypes.START_CHANGE_PASSWORD_REQUEST,
		payload: {
			errorMessage: undefined,
			loading: true,
			success: false,
		},
	};
}

function receiveSuccessResponse() {
	return {
		type: actionTypes.RECEIVE_SUCCESS_RESPONSE,
		payload: {
			newPassword: ``,
			confirmPassword: ``,
			errorMessage: undefined,
			loading: false,
			success: true,
		},
	};
}

export function receiveErrorResponse(errorMessage) {
	return {
		type: actionTypes.RECEIVE_ERROR_RESPONSE,
		payload: {
			errorMessage,
			loading: false,
			success: false,
		},
	};
}

async function makeChangePasswordRequest(accessToken, email, password) {
	const body = { email, password };
	const opts = {
		method: `post`,
		headers: getRequestHeaders(accessToken),
		body: JSON.stringify(body),
	};
	return fetch(`${config.variables.BACKEND_API_URL}/staff/password`, opts);
}

export function attemptChangePassword(email, password) {
	return async (dispatch, getState) => {
		try {
			dispatch(startChangePasswordRequest());

			const state = getState();
			const { accessToken, refreshToken } = state.session;
			const emailFromState = state.session.email;
			let res;
			res = await makeChangePasswordRequest(accessToken, email, password);

			if (res.status === 401) {
				const newTokens = await getNewTokens(accessToken, refreshToken);
				const newTokensObj = await newTokens.json();
				dispatch(receiveAccessToken(emailFromState, newTokensObj.response.token, newTokensObj.response.refresh_token));
				res = await makeChangePasswordRequest(newTokensObj.response.token, email, password);
			}

			if (res.status !== 200) {
				throw new Error(
					res.status === 400 ? `Please make sure your password is at least 8 characters` : `Unable to change password`,
				);
			}

			dispatch(receiveSuccessResponse());
		} catch (err) {
			dispatch(receiveErrorResponse(err.message));
		}
	};
}

export function reset() {
	return { type: actionTypes.RESET };
}
