import React, { useState } from 'react';
import classNames from 'classnames';
import styles from './FieldFileUpload.module.scss';
import Preview from '../file-preview/FilePreview';
import FileBrowser from '../../screens/file-browser/FileBrowser';
import Button from '../button/Button';

function FieldFileUpload({ className, accessToken, input, fileType }) {
	const cls = classNames({
		[styles.fieldFileUpload]: true,
		[className]: !!className,
	});
	const [previewState, setPreviewState] = useState(`NONE`);
	const [previewError, setPreviewError] = useState(``);
	const [showModal, setShowModal] = useState(false);

	return (
		<div className={cls}>
			<input {...input} type="text" className={styles.fileKey} />
			{/* open file browser */}
			<Button onClick={() => setShowModal(true)}>Browse</Button>
			{showModal ? <FileBrowser
				onClose={() => setShowModal(false)}
				onSelect={(file) => input.onChange(file)} /> :
				<div className={styles.previewContainer}>
					<Preview
						accessToken={accessToken}
						fileType={fileType}
						inputValue={input.value}
						previewState={previewState}
						setPreviewState={setPreviewState}
						previewError={previewError}
						setPreviewError={setPreviewError}
					/>
				</div>
			}
		</div>
	);
}

export default FieldFileUpload;
