import React from 'react';
import Button from '../shared/button/Button';
import { CloseIcon } from '../shared/icons';
import { connectToStore } from '../store';
import styles from './Modal.module.scss';

function Modal({ children, onClose, title, onAction, actionDisabled }) {
    return (
        <div className={styles["overlay"]}>
            <div className={styles["modal-main"]}>
                {/* modal header */}
                <div className={styles["title-bar"]}>
                    <h3>{title}</h3>
                    <Button onClick={onClose} className={styles["close-btn"]}>
                        <CloseIcon color={`#07bab4`} className={`fa-lg`} />
                    </Button>
                </div>
                {/* modal content */}
                <div className={styles["content"]}>
                    {children}
                </div>
                {/* modal footer */}
                <div className={styles["title-bar"]}>
                    <Button onClick={onClose}>Cancel</Button>
                    <Button disabled={actionDisabled} onClick={() => {
                        onAction();
                        onClose();
                    }}>Select</Button>
                </div>
            </div>
        </div>
    )
}

export default connectToStore(Modal);
