import React from 'react';
import { Switch, Route, useRouteMatch } from 'react-router-dom';
import { connectToStore } from '../../store';
import ContentList from './content-list/ContentList';
import ContentItemEditor from './content-item-editor/ContentItemEditor';
import ContentThemeEditor from './content-theme-editor/ContentThemeEditor';
import ContentTagEditor from './content-tag-editor/ContentTagEditor';
import NotFound from '../not-found/NotFound';
import styles from './Content.module.scss';

const subRoutes = [
	{
		path: `/`,
		exact: true,
		component: ContentList,
	},
	{
		path: `/items/:id`,
		exact: true,
		component: ContentItemEditor,
	},
	{
		path: `/themes/:id`,
		exact: true,
		component: ContentThemeEditor,
	},
	{
		path: `/tags/:id`,
		exact: true,
		component: ContentTagEditor,
	},
];

function Content() {
	const { path } = useRouteMatch();

	return (
		<div className={styles.screen}>
			<Switch>
				{/* Routes */}
				{subRoutes.map((route, index) => (
					<Route key={index} path={`${path}${route.path}`} exact={route.exact}>
						<route.component />
					</Route>
				))}

				{/* 404 */}
				<Route path="*">
					<NotFound />
				</Route>
			</Switch>
		</div>
	);
}

export default connectToStore(Content);
